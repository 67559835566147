import styles from './shareLink.module.scss';

const index = ({ icon, text, onClick }) => {
  return (
    <div className={styles.shareLinkWrap} onClick={onClick}>
      {icon}
      <p className="clr-dark-green fw-bold mb-0 f-s12">{text}</p>
    </div>
  );
};
export default index;
