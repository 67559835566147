import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
  ChevronIcon,
  CZLogo,
  LocationFilledIcon,
  LocationOutlineIcon,
  DeliveryIconLocation,
  GeoLocationWhiteIcon,
  DeliveryIconMobile,
  CartFilledIcon,
  CartOutlineIcon,
  UserIcon,
} from 'src/assets/images/Svgs/svg';
import SignUpButton from 'src/components/AccountStatus/SignUpButton';
import CustomModal from 'src/components/customModal/CustomModal';
import ItemOrderModal from 'src/components/ItemOrderModal/ItemOrderModal';
import Loader from 'src/components/Loader/Loader';
import NavbarComponent from 'src/components/NavbarComponent/NavbarComponent';
import * as CONSTANT from 'src/constants';
import UserName from 'src/Features/AccountSetup/components/UserName';
import { guestLogin } from 'src/Features/AccountSetup/redux/actions';
import Cart from 'src/Features/Cart/Cart';
import { getUser, getVisitorId } from 'src/helper/helperMethods';
import useGetCustomerPlacedOrders from 'src/react-query-hooks/useGetCustomerPlacedOrders';
import { setShowCart } from 'src/redux/slices/cartSlice';
import { useAppSelector } from 'src/redux/store/store';

import styles from './header.module.scss';
import LiveOrder from './LiveOrder';
import MultiOrderModal from './MultiOrderModal';
import TopHeaderBar from './TopHeaderBar';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import { Link, NavLink } from 'react-router-dom';
import logoImage from '../../../assets/images/home-page/logo.png';

const Header = () => {
  const navLinks = [
    {
      item: 'HOME',
      href: '/',
    },
    {
      item: 'ORDER',
      href: CONSTANT.ROUTE_MENU,
    },
    {
      item: 'REWARDS',
      href: CONSTANT.ROUTE_LOYALTY,
    },
    {
      item: 'OUR KITCHEN',
      href: CONSTANT.ROUTE_OUR_KITCHEN,
    },
  ];

  const { user } = useAppSelector((state) => state?.user);
  const cart = useAppSelector((state) => state.cart);
  const cartItems = cart.items;
  const show = cart.showCart;
  const setShow = (show) => {
    dispatch(setShowCart(show));
  };

  const handleClick = (href: string) => {
    if (
      href === 'https://cafezupas.com/' ||
      href.startsWith('https://cafezupas.com/')
    ) {
      window.open(href, '_blank');
    } else {
      history.push(href);
    }
  };

  const locationInfo = useAppSelector((state) => state.location);
  const isActiveOrders = useAppSelector(
    (state) => state.orderDetails.futureOrder.activeOrders,
  );
  const location = useLocation();
  const [showLocationSearch, setShowLocationSearch] = useState<boolean>(false);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [multiOrderModal, setMultiOrderModal] = useState(false);
  const [authLoading, setAuthLoading] = useState<boolean>(false);
  const history = useHistory();
  const isItMobile = useCheckMobileScreen();
  const [openMegaMenu, setOpenMegaMenu] = useState<boolean>(false);
  const [megaMenuAnimateClass, setMegaMenuAnimateClass] = useState('');
  const [rotateMenuIcon, setRotateMenuIcon] = useState('');
  const [addShadowOnScroll, setAddShadowOnScroll] = useState(false);
  const [addressConfirmationModal, setAddressConfirmationModal] = useState<{
    showModal: boolean;
    showCart: boolean;
  }>({ showModal: false, showCart: false });

  const {
    refetch: refetchGetCustomerPlacedOrders,
    data: { data: placedOrders = [] } = [],
    isFetching: isFetchingPlacedOrders,
    refetch: fetchItem,
  } = useGetCustomerPlacedOrders({ userId: user?.id, userType: user?.type });
  const isPublicTrackingRoute = location.pathname.includes(
    CONSTANT.ROUTE_ORDER_TRACKING,
  );

  useEffect(() => {
    if (multiOrderModal || location.pathname === '/thankYou') {
      fetchItem();
    }
  }, [multiOrderModal, location]);

  const shouldShowLiveTracker =
    !isFetchingPlacedOrders &&
    placedOrders?.length &&
    !isPublicTrackingRoute &&
    isActiveOrders;
  const authenticateGuest = async () => {
    try {
      setAuthLoading(true);
      const payload = {
        visitor_id: getVisitorId(),
      };
      await guestLogin(dispatch, payload);
      setAuthLoading(false);
      return true;
    } catch (error) {
      setAuthLoading(false);
      return false;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setAddShadowOnScroll(window.scrollY > 20);
    });
  }, []);

  const HandleShowMegaMenu = () => {
    setOpenMegaMenu(true);

    setTimeout(() => {
      setMegaMenuAnimateClass('opacity-100');
      setRotateMenuIcon(styles.rotate180);
    }, 0);
  };

  const HandleHideMegaMenu = () => {
    setMegaMenuAnimateClass('opacity-0');
    setRotateMenuIcon(styles.rotate360);
    setTimeout(() => {
      setOpenMegaMenu(false);
    }, 300);
  };

  const verifyAddress = () => {
    return (
      cart.orderType === CONSTANT.DELIVERY_ORDER_TYPE &&
      user.type !== CONSTANT.GUEST_USER &&
      cart?.orderDetails?.delivery_address?.isGuestAddress
    );
  };
  const handleLocationButton = () => {
    if (verifyAddress()) {
      setAddressConfirmationModal({ showModal: true, showCart: false });
      return;
    }
    setShowLocationSearch(true);
    setOpenOrderModal(true);
  };
  const viewCartHandler = async () => {
    if (location.pathname.includes(CONSTANT.ROUTE_CHECKOUT)) {
      history.push(CONSTANT.ROUTE_MENU);
      setShow(true);
    } else {
      if (authLoading) return;
      if (!cart.isOrderTypeSelected) {
        setOpenOrderModal(true);
      } else if (verifyAddress())
        setAddressConfirmationModal({ showModal: true, showCart: true });
      else setShow(true);
    }
  };

  const locationLabel = () => {
    if (cart.isOrderTypeSelected) {
      return cart.orderType === CONSTANT.DELIVERY_ORDER_TYPE ? (
        <>
          {' '}
          {isItMobile ? (
            <>
              {' '}
              <span className={`${styles.borderBottomLocation}`}>
                {' '}
                Delivery
              </span>{' '}
              <DeliveryIconMobile />{' '}
            </>
          ) : (
            <>
              <DeliveryIconMobile />
              <span className={`${styles.borderBottomLocation}`}>
                {' '}
                Delivery
              </span>
            </>
          )}{' '}
        </>
      ) : (
        (
          <>
            {isItMobile ? (
              <>
                <span className={`${styles.borderBottomLocation}`}>
                  {' '}
                  {locationInfo?.selectedStore?.name}{' '}
                </span>
                <LocationFilledIcon />
              </>
            ) : (
              <>
                <LocationFilledIcon />
                <span className={`${styles.borderBottomLocation}`}>
                  {' '}
                  {locationInfo?.selectedStore?.name}{' '}
                </span>
              </>
            )}
          </>
        ) ?? <> </>
      );
    } else
      return (
        <div>
          {isItMobile ? (
            <>
              <span className={`${styles.borderBottomLocation} `}>
                {' '}
                Locations{' '}
              </span>{' '}
              <LocationOutlineIcon />
            </>
          ) : (
            <>
              <LocationOutlineIcon />
              <span className={`${styles.borderBottomLocation}`}>
                {' '}
                find a cafe zupas{' '}
              </span>{' '}
            </>
          )}{' '}
        </div>
      );
  };

  const closeOpenOrderModal = () => {
    setOpenOrderModal(false);
  };

  useEffect(() => {
    if (!user?.id && !authLoading) authenticateGuest();
  }, [user?.id]);
  const dispatch = useDispatch();
  const userId = user?.id ?? '';
  const userType = user?.type ?? '';

  return (
    <>
      <div
        className={`${styles.headerWrapper} ${
          addShadowOnScroll ? styles.Headershadow : null
        }`}
      >
        <Container
          fluid
          className={`${styles.navContainer} ps-md-1 pe-md-3 px-3`}
        >
          <Row className={styles.header_row}>
            <Col
              xs="12"
              className={`${styles.header_logo_wrap} d-flex justify-content-between flex-md-column`}
            >
              {/* Mene Links Wrap */}

              {!isItMobile ? (
                <div className={`${styles.mainHeaderWrapper} px-4 `}>
                  {!!shouldShowLiveTracker && (
                    <LiveOrder setMultiOrderModal={setMultiOrderModal} />
                  )}
                  <div className="row">
                    <div className="col-md-5">
                      <TopHeaderBar
                        handleLocationButton={handleLocationButton}
                        locationLabel={locationLabel}
                      />
                    </div>
                    <a
                      className={`${styles.logo} text-center col-md-2`}
                      href={'/'}
                    >
                      <img
                        className="sticky-logo"
                        src={logoImage}
                        alt="sticky logo"
                      />
                    </a>
                    <div
                      className={`d-flex align-items-center justify-content-end col-md-5 ${styles.headerRight}`}
                    >
                      <button
                        type="button"
                        className={`${
                          styles.locationNavSection +
                          ' ' +
                          (cart.orderType === CONSTANT.DELIVERY_ORDER_TYPE
                            ? styles.online_location_icon
                            : '')
                        } d-flex justify-content-center align-items-center`}
                        onClick={handleLocationButton}
                      >
                        {locationLabel()}
                      </button>
                      {userId && userType !== CONSTANT.GUEST_USER ? (
                        <UserName
                          userType={userType}
                          userId={userId}
                          userRefreshToken={user?.refresh_token}
                        />
                      ) : (
                        <SignUpButton />
                      )}
                      <button
                        type="button"
                        className={`${styles.add_to_card_btn} font-Vcf d-flex align-items-end justify-content-center clr-dark-green`}
                        onClick={viewCartHandler}
                      >
                        {cartItems.length ? (
                          <CartFilledIcon />
                        ) : (
                          <CartOutlineIcon />
                        )}
                        <span className={cartItems.length && 'clr-white'}>
                          {' '}
                          {cartItems.length}{' '}
                        </span>
                      </button>
                    </div>
                    {/* </div> */}
                  </div>

                  <div className={styles.headerInnerDiv}>
                    <ul className="mb-0 ps-0 d-flex align-items-center w-100 justify-content-between">
                      {navLinks.map((item, i) => {
                        return (
                          <NavLink
                            className={`${styles.headerNavLinks} header-menu-font`}
                            to={item.href}
                            activeClassName={styles.activeLink}
                            key={i}
                            exact={item.item === 'HOME'}
                          >
                            {item.item}
                          </NavLink>
                        );
                      })}
                      <a
                        className={`${styles.headerNavLinks} header-menu-font`}
                        href={''}
                      >
                        {'INDIVIDUAL'}
                      </a>
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="w-100">
                  {!!shouldShowLiveTracker && (
                    <LiveOrder setMultiOrderModal={setMultiOrderModal} />
                  )}

                  <TopHeaderBar
                    locationLabel={locationLabel}
                    handleLocationButton={handleLocationButton}
                  />

                  <div
                    className={`${styles.header_menu_Wrapper} ${styles.mainHeaderWrapper} d-flex justify-content-between align-items-center w-100  `}
                  >
                    <NavbarComponent></NavbarComponent>
                    <a className={styles.logo} href={'/'} aria-label="CZ Logo">
                      <img
                        className="sticky-logo"
                        src={logoImage}
                        alt="sticky logo"
                      />
                    </a>
                    <div
                      className={`d-flex align-items-center  ${styles.headerRight}`}
                    >
                      {userType !== CONSTANT.GUEST_USER ? (
                        <UserName
                          userType={userType}
                          userId={userId}
                          userRefreshToken={user?.refresh_token}
                        />
                      ) : (
                        <SignUpButton />
                      )}
                      <Link
                        to={
                          userType === CONSTANT.GUEST_USER
                            ? CONSTANT.ROUTE_LOGIN
                            : CONSTANT.ROUTE_PROFILE_DETAILS
                        }
                      >
                        <UserIcon />
                      </Link>
                      <button
                        type="button"
                        className={`${styles.add_to_card_btn} font-Vcf d-flex align-items-end justify-content-center clr-dark-green`}
                        onClick={viewCartHandler}
                      >
                        {cartItems.length ? (
                          <CartFilledIcon />
                        ) : (
                          <CartOutlineIcon />
                        )}
                        <span className={cartItems.length && 'clr-white'}>
                          {' '}
                          {cartItems.length}{' '}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
        <div>
          {/* {!!shouldShowLiveTracker && (
            <LiveOrder setMultiOrderModal={setMultiOrderModal} />
          )} */}

          <CustomModal
            showModal={multiOrderModal}
            title={''}
            closeModal={() => setMultiOrderModal(false)}
            size="xl"
            modalDialogClasses="select_date_time"
          >
            {isFetchingPlacedOrders ? (
              <Loader modalSpinner={true} />
            ) : (
              <MultiOrderModal
                data={placedOrders}
                setMultiOrderModal={setMultiOrderModal}
              />
            )}
          </CustomModal>
          <CustomModal
            showModal={openOrderModal}
            title={''}
            closeModal={closeOpenOrderModal}
            modalDialogClasses={'itemOrderModal'}
            modalBodyClass={'overflow-visible'}
          >
            <ItemOrderModal
              itemPath={null}
              closeOpenOrderModal={closeOpenOrderModal}
              searchLocations={showLocationSearch}
              goBack={false}
              showModal={openOrderModal}
            />
          </CustomModal>
        </div>
      </div>
      {show ? <Cart /> : null}
    </>
  );
};

export default Header;
