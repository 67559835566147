import _clonedeep from 'lodash.clonedeep';
import React from 'react';
import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import {
  FULL_SIZE,
  HALF_SIZE,
  MODIFIER_LOCATION,
  s3BaseUrl,
} from 'src/constants';
import { verifyStatus } from 'src/helper/customizedItem/customizedItem.helper';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import { mapSizesKey } from 'src/models/item.model';
import {
  addAdditionalPrice,
  byDefaultSeletedItems,
  calculateAndUpdateTotalAdditionalPrice,
} from 'src/priceCalculation/helper';
import { useAppDispatch, useAppSelector } from 'src/redux/store/store';

import CounterCard from '../../components/Cards/CounterCard/CounterCard';
import { useLocation } from 'react-router';

const ItemsAsModifiers: React.FC<any> = (props) => {
  const {
    itemAsModifiers,
    cardColSize,
    isPackege,
    itemAsModifierState,
    itemsAsModifiersToHandleScroll,
    openItemInfoModal,
  } = props;
  const order = useAppSelector((state) => state.itemCustomization);

  const { search, state } = useLocation();
  const query = new URLSearchParams(search);
  const isPackage = Boolean(query.get('is_package'));

  const {
    onAddModifier,
    onRemoveModifier,
    selectedItemAsAnModifier,
    lengthOfSelectedItems,
  } = useItemAsModifiers(
    _clonedeep(itemAsModifiers),
    order,
    itemAsModifierState,
  );

  const isLimitCompleted = (currentItemGroup: any) => {
    const currentItemGroupId = currentItemGroup.id;
    const { itemsAsModifier, setItemsAsModifier } = itemAsModifierState;
    const selectedItems = itemsAsModifier?.find(
      (item) => item.id === currentItemGroupId,
    )?.selectedItems;
    if (!selectedItems || selectedItems?.length <= 0) return false;
    if (order.size === HALF_SIZE.id) {
      return (
        currentItemGroup.half_item_count <=
        lengthOfSelectedItems(currentItemGroupId)
      );
    }
    if (order.size === FULL_SIZE.id)
      return (
        currentItemGroup.full_item_count <=
        lengthOfSelectedItems(currentItemGroupId)
      );
  };

  const showRedBorderAnimation = (index) => {
    return itemsAsModifiersToHandleScroll
      ? itemsAsModifiersToHandleScroll.isItemAsModifierSelected.status &&
          itemsAsModifiersToHandleScroll.isItemAsModifierSelected
            .highLightArea === index
      : false;
  };

  const itemAsModifierGroupLabel = (items, index: number) => {
    return (
      <span
        ref={
          itemsAsModifiersToHandleScroll
            ? itemsAsModifiersToHandleScroll
                .refToScrollToMissingRequiredItemAsModifiers[items.id]
            : null
        }
        className={`d-block text-capitalize ${
          isPackage && 'clr-dark-gray f-s23 f-sm-s18 text-capitalize'
        }`}
      >
        {items?.label}
        {!isLimitCompleted(items) ? (
          <span className="f-s13 f-w6 font-rale clr-dark-green d-block lh-normal">
            <span className={`caption fs-14`}>
              You are missing items in this category
            </span>
          </span>
        ) : null}
      </span>
    );
  };

  const handleInfoOutline = (e: any, item) => {
    openItemInfoModal(item);
    e.stopPropagation();
  };

  const itemAsModifierGroupModifiers = (items, index) => {
    return (
      <div className="single-item-modifiers row">
        {items.items_as_modifier.map((item) => {
          const selectedItem = selectedItemAsAnModifier(items.id, item);
          return (
            <div
              key={item?.id}
              className={`item__modifier_item mb-3 ${cardColSize} col-4 col-md-3 col-lg-2 px-2`}
            >
              <CounterCard
                id={item.id}
                name={item.name}
                image={`${s3BaseUrl}${item?.image}`}
                modifierAction={{
                  onAdded: () => onAddModifier(items, item),
                  onRemove: () => onRemoveModifier(items, item),
                }}
                isSelected={selectedItem?.isSelected}
                count={selectedItem?.quantity ?? 0}
                limitCompleted={isLimitCompleted(items)}
                isActive={verifyStatus(item, MODIFIER_LOCATION)}
                selectedItem={selectedItem}
                showRedBorderAnimation={showRedBorderAnimation(items.id)}
                infoIconClick={(e) => handleInfoOutline(e, item)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <React.Fragment>
      {itemAsModifiers?.length > 0 &&
        itemAsModifiers?.map((items, index) => {
          return (
            <Container fluid className="modifiersContainer">
              <Accordion
                key={items.id}
                defaultActiveKey={['1']}
                alwaysOpen
                className="theme-custom-accordions item-customization-acc modifiers-accordions-wrapper"
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {itemAsModifierGroupLabel(items, index)}
                  </Accordion.Header>
                  <Accordion.Body>
                    {itemAsModifierGroupModifiers(items, index)}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Container>
          );
        })}
    </React.Fragment>
  );
};

const useItemAsModifiers = (items: any, order, itemAsModifierState) => {
  const { itemsAsModifier, setItemsAsModifier } = itemAsModifierState;
  const { search, state } = useLocation();
  const query = new URLSearchParams(search);
  const isPackage = Boolean(query.get('is_package'));
  const dbId = query.get('id');

  const dispatch = useAppDispatch();
  const isMobile = useCheckMobileScreen();

  function onAddModifier(currentItemsGroup: any, newItem) {
    calculateAndUpdateTotalAdditionalPrice(itemsAsModifier, '-', dispatch);
    const groupId: number = currentItemsGroup.id;
    newItem = {
      ...newItem,
      quantity: currentItemsGroup.item_counter_increment,
      additionalPrice: newItem?.additionalPrice ?? 0,
      isSelected: true,
    };
    if (newItem[mapSizesKey[order.size]] && !newItem.isByDefault) {
      newItem.isByDefault = true;
      newItem.actualQuantity =
        currentItemsGroup.item_counter_increment *
        newItem[mapSizesKey[order.size]];
    }
    const newItemsAsModifiers = [...itemsAsModifier];
    newItemsAsModifiers
      .find((item) => item.id === groupId)
      .selectedItems.push(newItem);
    const itemsWithAdditionalPrice = addAdditionalPrice({
      items: newItemsAsModifiers.find((item) => item.id === groupId)
        .selectedItems,
      currentItemsGroup,
      order,
    });
    newItemsAsModifiers.find((item) => item.id === groupId).selectedItems =
      itemsWithAdditionalPrice;
    calculateAndUpdateTotalAdditionalPrice(newItemsAsModifiers, '+', dispatch);
    setItemsAsModifier(newItemsAsModifiers);
  }

  function lastIndexOfItemAsModifiers(array: any, key: any): number {
    for (let i = array.length - 1; i >= 0; i--) {
      if (array[i].id === key.id) return i;
    }
    return -1;
  }

  function onRemoveModifier(currentItemsGroup: any, newItem: any): void {
    calculateAndUpdateTotalAdditionalPrice(itemsAsModifier, '-', dispatch);
    const groupId = currentItemsGroup.id;
    const activeItems = currentActiveItems(groupId);
    const activeItemIndex = lastIndexOfItemAsModifiers(activeItems, newItem);
    if (
      activeItems[activeItemIndex].quantity ===
      currentItemsGroup.item_counter_increment
    ) {
      activeItems.splice(activeItemIndex, 1);
    } else if (
      activeItems[activeItemIndex].quantity >
      currentItemsGroup.item_counter_increment
    ) {
      activeItems[activeItemIndex].quantity -=
        currentItemsGroup.item_counter_increment;
    }

    const newItemsAsModifiers = [...itemsAsModifier];
    newItemsAsModifiers.find((item) => item.id === groupId).selectedItems =
      addAdditionalPrice({
        items: activeItems,
        currentItemsGroup,
        order,
      });
    calculateAndUpdateTotalAdditionalPrice(newItemsAsModifiers, '+', dispatch);

    setItemsAsModifier(newItemsAsModifiers);
  }

  function selectedItemAsAnModifier(groupId: number, item: any) {
    const currentItemGroup = _clonedeep(itemsAsModifier)?.find(
      (itemGroup: { id: number; selectedItems: any[] }, index: number) =>
        itemGroup.id === groupId,
    )?.selectedItems;

    const currentItem = currentItemGroup?.find(
      (selected) => selected.id === item.id,
    );
    if (currentItem) {
      currentItem.quantity = currentItemGroup
        .filter((selected) => selected.id === item.id)
        ?.reduce((acc, item) => acc + item.quantity, 0);
      currentItem.additionalPrice = currentItemGroup
        .filter((selected) => selected.id === item.id)
        ?.reduce((acc, item) => acc + item.additionalPrice, 0);
    }
    return currentItem;
  }

  function lengthOfSelectedItems(currentItemGroupId: number): number {
    const currentItems = itemsAsModifier.find(
      (itemGroup: { id: number; selectedItems: any[] }, index: number) =>
        itemGroup.id === currentItemGroupId,
    ).selectedItems;
    return currentItems.reduce((acc, item) => acc + item.quantity, 0);
  }

  function isActive(item): boolean {
    return verifyStatus(item, MODIFIER_LOCATION);
  }

  function currentActiveItems(itemGroupId): any[] {
    return [
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...itemsAsModifier?.find(
        (currentItemsGroup) => currentItemsGroup.id === itemGroupId,
      ).selectedItems,
    ];
  }

  return {
    onAddModifier,
    onRemoveModifier,
    byDefaultSeletedItems,
    selectedItemAsAnModifier,
    lengthOfSelectedItems,
  };
};

export default ItemsAsModifiers;
