import React from 'react';
import { Accordion, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  PACKAGE_SELECTED_SECTION,
  PREMIUM_PACKAGE_SELECTED_SECTION,
} from 'src/constants';
import { verifyStatus } from 'src/helper/customizedItem/customizedItem.helper';
import { IItemCustomization, ISubCategories } from 'src/models/item.model';

import AddIngredients from './AddIngredients';
import styleClasses from './order.module.scss';
import { useLocation } from 'react-router';

const PackegeCustomization: React.FC<IItemCustomization> = (props) => {
  const {
    item,
    showCustomization,
    showCustomizedOptions,
    handleIngredientSelection,
    autoScrollDivRef,
    firstTimeModal,
    cardColSize,
    boxLunchesModal,
    manualSelectedItem,
    itemAsModifierState,
    manualSelectedItemId,
    itemsAsModifiersToHandleScroll,
    openItemInfoModal,
  } = props;

  const nonRequriedTrays = useGetNonRequiredTrays(item?.package_categories);

  const trayName = (trayName) => {
    if (!trayName) return;
    if (!item.is_package) {
      return trayName;
    }
    const updatedTrayName = trayName.split('');
    if (updatedTrayName[updatedTrayName.length - 1] === 's') {
      updatedTrayName.splice(updatedTrayName.length - 1, 1);
      return updatedTrayName.join('');
    }
  };

  return (
    <>
      {item?.is_active &&
        verifyStatus(item, 'item_location') &&
        (showCustomizedOptions || showCustomization) && (
          <div
            ref={autoScrollDivRef}
            className={`${styleClasses.customize_content_wrapper}`}
          >
            {!firstTimeModal && (
              <div
                className={`${styleClasses.customize_card_header} d-flex align-items-center card_title_wrap mb-3 mx-md-3`}
              >
                <h2 className="clr-dark-green text-start text-uppercase f-s22 f-sm-s20 text-style-none mb-0">
                  Customize
                </h2>
              </div>
            )}
            <div className={`px-md-3 ${boxLunchesModal}`}>
              {manualSelectedItem && (
                <Accordion
                  defaultActiveKey="0"
                  className="modifiers-accordions-wrapper"
                  key={manualSelectedItem.id}
                >
                  <Accordion.Item
                    eventKey="1"
                    className="parentPacakgesAccordion"
                  >
                    <Accordion.Header>
                      <span className="d-block text-capitalize">
                        {trayName(manualSelectedItem?.category_name)}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body className="pb-0">
                      <Row>
                        <AddIngredients
                          modifierGroups={
                            manualSelectedItem?.items_modifier_groups
                          }
                          coreModifiers={manualSelectedItem?.core_modifiers}
                          requiredModifiers={
                            manualSelectedItem?.items_required_modifier_groups
                          }
                          itemsAsModifiers={
                            manualSelectedItem?.sub_items_required_modifier_groups
                          }
                          handleIngredientSelection={(data: any) =>
                            handleIngredientSelection(
                              data,
                              manualSelectedItemId,
                            )
                          }
                          selectedItem={manualSelectedItemId}
                          itemName={manualSelectedItem?.name}
                          showCustomization={showCustomization}
                          cardColSize={cardColSize}
                          isPackege={true}
                          openItemInfoModal={openItemInfoModal}
                        />
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}

              {nonRequriedTrays.map((data: any, index: 0 | 1) => {
                const trayId: any = index + 1;
                return (
                  <Accordion
                    defaultActiveKey="0"
                    className="modifiers-accordions-wrapper"
                    alwaysOpen
                    key={data.id}
                  >
                    <Accordion.Item
                      eventKey="1"
                      className="parentPacakgesAccordion"
                    >
                      <Accordion.Header>
                        <span className="d-block text-capitalize">
                          {trayName(data?.name)}
                        </span>
                      </Accordion.Header>
                      <Accordion.Body className="pb-0">
                        <Row>
                          <AddIngredients
                            modifierGroups={
                              data?.items[0]?.items_modifier_groups
                            }
                            coreModifiers={data?.items[0]?.core_modifiers}
                            requiredModifiers={
                              data?.items[0]?.items_required_modifier_groups
                            }
                            itemsAsModifiers={
                              data?.items[0]?.sub_items_required_modifier_groups
                            }
                            handleIngredientSelection={(data: any) =>
                              handleIngredientSelection(data, trayId)
                            }
                            itemName={data?.items[0]?.name}
                            showCustomization={showCustomization}
                            cardColSize={cardColSize}
                            isPackege={true}
                            itemAsModifierState={itemAsModifierState}
                            selectedItem={trayId}
                            itemsAsModifiersToHandleScroll={
                              itemsAsModifiersToHandleScroll
                            }
                            openItemInfoModal={openItemInfoModal}
                          />
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                );
              })}
            </div>
          </div>
        )}
    </>
  );
};

export default PackegeCustomization;

const useGetNonRequiredTrays = (trays: ISubCategories[]) => {
  const nonRequriedTrays = trays.filter(
    (tray: ISubCategories) => tray.items.length === 1,
  );
  return nonRequriedTrays;
};
