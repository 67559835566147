import React, { createContext, useState, useEffect, ReactNode } from 'react';
import CampaignTrigger from '../hooks/useCampaignTrigger';

interface CampaignTriggerContextType {
  campaignTriggerData: any;
  campaignTriggerLoading: boolean;
}

export const CampaignTriggerContext = createContext<
  CampaignTriggerContextType | undefined
>(undefined);

interface CampaignTriggerProviderProps {
  children: ReactNode;
}

export const CampaignTriggerProvider = ({
  children,
}: CampaignTriggerProviderProps) => {
  const { data: campaignTriggerData, loading } = CampaignTrigger();
  return (
    <CampaignTriggerContext.Provider
      value={{ campaignTriggerData, campaignTriggerLoading: loading }}
    >
      {children}
    </CampaignTriggerContext.Provider>
  );
};
