import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import UpsellItemCard from './UpsellItemCard';
import styleClasses from '../Offers/offer.module.scss';
import styles from '../CartItems/CartItems.module.scss';
import SkeltonLoader from 'src/components/Loader/SkeltonLoader';
import SkeletonLoaderForHeadings from 'src/components/Loader/SkeletonLoaderForHeadings';
interface IUpselItemList {
  data: any;
  cart: any;
  fetchUpsell: () => void;
  isFetchingUpsell: boolean;
  syncCart: () => any;
}

const UpsellItemsLists: React.FC<IUpselItemList> = (props) => {
  const { data, isFetchingUpsell } = props;

  const [showUpsell, setShowUpsell] = useState(true);
  useEffect(() => {
    if (isFetchingUpsell) return;
    if (data?.length <= 0) {
      setShowUpsell(false);
    } else {
      setShowUpsell(true);
    }
  }, [isFetchingUpsell, data]);

  return (
    showUpsell && (
      <React.Fragment>
        <div className={styleClasses.other_products_wrap}>
          <h4 className="f-s22 f-sm-s16 font-Cls lh-normal ls-normal mb-3">
            {data?.length > 0 ? data[0].header : <SkeletonLoaderForHeadings />}
          </h4>
          {isFetchingUpsell ? (
            <div className="d-flex justify-content-center">
              {/** you can add these upon having discussion with haseeb */}
              <SkeltonLoader />
              <SkeltonLoader />
              <SkeltonLoader />
            </div>
          ) : (
            <Row className={styleClasses.suggestion_row + ' flex-nowrap pt-1'}>
              <Scrollbars
                className={styleClasses.offers_scrolbar_height}
                renderThumbHorizontal={(props) => (
                  <div {...props} className={styleClasses.Scrollbar_thumb} />
                )}
                renderView={(props) => (
                  <div {...props} className={styleClasses.Scrollbar_view} />
                )}
              >
                {data?.map((item, index: number) => (
                  <>
                    <UpsellItemCard
                      key={item.id}
                      upSellItem={item}
                      btnText={'Add'}
                    />
                  </>
                ))}
              </Scrollbars>
            </Row>
          )}
        </div>
        <div className="shadow-divider"></div>
      </React.Fragment>
    )
  );
};

export default UpsellItemsLists;
