import { useQuery } from 'react-query';
import { customerBusinessService } from '../services';

const triggerCampaign = async (offerPayload: any) => {
  try {
    const response = await customerBusinessService.triggerCampaign(
      offerPayload,
    );
    return response.data.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export default function useTriggerCampaign(payload: any, enabled) {
  return useQuery(['useTriggerCampaign'], () => triggerCampaign(payload), {
    enabled: enabled,
  });
}
