import { Formik } from 'formik';
import React from 'react';
import { ISelectedAddress } from '../../../models/forms.model';
import styleClasses from '../ItemGrid.module.scss';
import { selectDeliveryAddressSchema } from '../../../validationSchemas/selectDeliveryAddressSchema';
import { validateAddressCount, validateCount } from 'src/helper/helperMethods';
import FormField from 'src/components/FormFields/FormField';
import SelectedAddressForDelivery from './SelectedAddressForDelivery';
import Button from 'src/components/Button/Button';

interface IUserAddressDropdown {
  isGuestAddressSelected: boolean;
  userAddresses: any[];
  newAddedAddressForDelivery: any;
  isNearestStoreAvailable: boolean;
  handleFormSubmission: any;
  setAddress: (any) => void;
  checkNearestStoreLocation: (any) => any;
  selectedAddress: any;
  setAddGuestAddress: (boolean) => void;
  isFindingNearest: boolean;
}

const UserAddressDropdown = (props: IUserAddressDropdown) => {
  const initialFormState: ISelectedAddress = {
    selected_address:
      props.newAddedAddressForDelivery ||
      props?.userAddresses?.find((address) => address.isDefault == 1),
  };
  return (
    <>
      <p
        className={`${styleClasses.delivery_alert_text} clr-dark-green text-center f-s14 pb-4`}
        hidden={props.isNearestStoreAvailable}
      >
        Option unavailable. Unfortunately, you’re too far from the nearest Cafe
        Zupas location.
      </p>
      <Formik
        initialValues={initialFormState}
        onSubmit={props.handleFormSubmission}
        validationSchema={selectDeliveryAddressSchema}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          return (
            <form
              className="new_form_design w-75 my-0 mx-auto delivery_location_select full_width_mob"
              onSubmit={handleSubmit}
            >
              {!props.isGuestAddressSelected && (
                <FormField
                  isSearchable={true}
                  options={props.userAddresses}
                  labelText={'Delivery LOCATION'}
                  name="selected_address"
                  type="select"
                  placeholder={'Select a delivery address'}
                  value={values.selected_address}
                  onChange={(address) => {
                    setFieldValue('selected_address', address);
                    props.setAddress(address);
                    props.checkNearestStoreLocation(address);
                  }}
                  errors={errors}
                  touched={touched}
                  inputFieldClass={'custom_select custom_multi_select mb-0'}
                />
              )}
              {props.selectedAddress && (
                <SelectedAddressForDelivery
                  selectedAddress={props.selectedAddress}
                />
              )}
              <div className="d-flex justify-start-center my-3">
                <button
                  type="button"
                  className={`btn p-0 f-w6 clr-dark-green font-Vcf mt-3`}
                  onClick={() => {
                    if (validateAddressCount(props.userAddresses)) {
                      props.setAddGuestAddress(true);
                    }
                  }}
                >
                  {props.isGuestAddressSelected
                    ? 'Change Address'
                    : 'Add a New Address'}
                </button>
              </div>
              <div className="text-center mt-3">
                <Button
                  disabled={
                    isSubmitting ||
                    !props.isNearestStoreAvailable ||
                    props.isFindingNearest
                  }
                >
                  {props.isFindingNearest
                    ? 'Searching for nearest store...'
                    : 'Continue'}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default UserAddressDropdown;
