import React from 'react';
import styleClasses from '../../Features/AccountSetup/account.module.scss';
interface IFavoriteIconProps {
  handleFavoriteToggle: (e) => void;
  favorite?: boolean;
}

export default function FavoriteLocation({
  handleFavoriteToggle,
  favorite,
}: IFavoriteIconProps) {
  return (
    <button
      type="button"
      className={`${styleClasses.favourite_icon_wrap} btn btn-link shadow-none`}
      onClick={(e) => handleFavoriteToggle(e)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="28"
        viewBox="0 0 31 28"
        fill="none"
      >
        <path
          style={{
            ...(favorite
              ? {
                  fill: '#198000',
                  stroke: '#198000',
                }
              : {
                  stroke: '#000',
                }),
          }}
          d="M15.3643 26.2812C15.3643 26.2812 1 18.2372 1 8.46947C1.00029 6.74307 1.59849 5.07005 2.6929 3.73486C3.78731 2.39967 5.31037 1.48473 7.00313 1.14558C8.69589 0.806437 10.4539 1.06403 11.9782 1.87456C13.5025 2.68509 14.699 3.99853 15.3644 5.59157L15.3643 5.59158C16.0297 3.99854 17.2262 2.6851 18.7505 1.87457C20.2748 1.06403 22.0328 0.806438 23.7255 1.14558C25.4183 1.48472 26.9414 2.39967 28.0358 3.73486C29.1302 5.07005 29.7284 6.74307 29.7287 8.46947C29.7287 18.2372 15.3643 26.2812 15.3643 26.2812Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
}
