import React, { useState } from 'react';
import { useCallback } from 'react';
import { Col } from 'react-bootstrap';
import _debounce from 'lodash/debounce';
import {
  Close,
  CurrentLocationIcon,
  MagnifyingGlass,
  MyLocaion,
} from 'src/assets/images/Svgs/svg';

interface ISearchLocationProps {
  styleClasses: { readonly [key: string]: string };
  searchLocationProps: any;
  locationRenderProps: any;
  noLocationProps: any;
}

const SearchLocation: React.FC<ISearchLocationProps> = ({
  styleClasses,
  searchLocationProps,
  locationRenderProps,
  noLocationProps,
}) => {
  const {
    handleSearchChange,
    handleEnter,
    handleCurrentLocation,
    removeSearch,
  } = searchLocationProps;

  const [search, setSearch] = useState('');

  const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

  function handleDebounceFn(searchValue) {
    handleSearchChange(searchValue);
  }

  function handleChange(event) {
    setSearch(event.target.value);
    if (event.key === 'Enter') {
      handleSearchChange(event.target.value);
    } else {
      debounceFn(event.target.value);
    }
  }

  function removeClickHandler() {
    setSearch('');
    removeSearch();
  }

  return (
    <>
      <h4 className="f-s14 h-17 text-uppercase font-Visby-cf f-w8">
        Store Location
      </h4>
      <Col md="12" lg="12" xxl="12">
        <div className={styleClasses.search_bar_wrap}>
          <div
            className={`${styleClasses.search_inn_content} d-flex align-items-center px-3 py-1`}
          >
            {/* <img
                            src={require("../../../../../src/images/search-icon.svg")}
                            className="img-fluid"
                            alt="icon"
                        /> */}
            <input
              type="text"
              className={`${styleClasses.form_control} form-control `}
              // onChange={(e) => setTargetState(e.target.value)}
              // onKeyPress={(e) => e.key === "Enter" && searchHandle()}
              // value={targetState}
              // autoFocus
              placeholder="Search Recent Orders"
              value={search}
              onChange={(e) => handleChange(e)}
              onKeyUp={(e) => handleChange(e)}
              required
            />
            <span className={styleClasses.search_bar_icon}>
              {/* <img
                                src={require("../../../../../src/images/Close.svg")}
                                className="img-fluid"
                                alt="icon"
                                onClick={removeClickHandler}
                            /> */}
            </span>
          </div>
        </div>
      </Col>
      <div className="d-flex justify-content-md-start justify-content-center  my-3">
        <button
          type="button"
          className={`btn btn-custom p-0 f-w6 p-16 clr-dark-green font-Vcf f-s16 d-flex align-items-center gap-2`}
          onClick={(e) => handleCurrentLocation()}
        >
          <CurrentLocationIcon />
          Use My Current Location
        </button>
      </div>
    </>
  );
};

export default SearchLocation;
