import { useQuery } from 'react-query';
import axios from 'axios';
import { GUEST_USER, ORDER_MICRO_SERIVCE } from '../constants';
import { useDispatch } from 'react-redux';
import { updateTrackOrder } from 'src/redux/slices/checkoutSlice';

interface IQueryParams {
  userId: number;
  userType: string;
}

const getCustomerPlacedOrders = async (
  { userId, userType }: IQueryParams,
  dispatch,
) => {
  const response = await axios.get(
    `${ORDER_MICRO_SERIVCE}/orders/history/${userId}?orderType=future&page=1&is_catering=1`,
  );
  dispatch(updateTrackOrder(response?.data?.data?.data?.length ? true : false));
  return response?.data?.data;
};

export default function useGetCustomerPlacedOrders({
  userId,
  userType,
}: IQueryParams) {
  const dispatch = useDispatch();
  return useQuery(
    ['placedOrders', userId],
    () => getCustomerPlacedOrders({ userId, userType }, dispatch),

    {
      enabled: userType !== GUEST_USER,
      cacheTime: 0,
      initialData: undefined,
      staleTime: 0,
    },
  );
}
// orders/history/313?page=1&is_catering=1

// https://order-business-service.qa.zupas.net/api/v1/orders/351?details=payment,store,pickup,tracking,am_here
