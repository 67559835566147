import { createSlice } from '@reduxjs/toolkit';
import { getUser } from 'src/helper/helperMethods';
const user = getUser();

const userId = user.id ?? '';
const authentication_token = user.authentication_token ?? '';
const access_token = user.access_token ?? '';
const visitor_id = user.visitor_id ?? '';
const type = user.type ?? '';

const initialStateValues = {
  user: {
    id: '' || userId,
    visitor_id: '' || visitor_id,
    authentication_token: '' || authentication_token,
    access_token: '' || access_token,
    loading: false,
    errorMessage: null,
    type: '' || type,
    refresh_token: '',
    refresh_expires_at: '',
    expires_at: '',
    encoded_cz_id: null,
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialStateValues,
  reducers: {
    updateUserData: (state, action) => {
      state.user = {
        ...initialStateValues.user,
        id: action.payload.id,
        authentication_token: action.payload.authentication_token,
        access_token: action.payload.access_token,
        loading: false,
        type: action.payload.type,
        visitor_id:
          action.payload.visitor_id ?? initialStateValues.user.visitor_id,
        // loyalty_user_id: action?.payload?.loyalty_user_id || '',
        refresh_token: action.payload.refresh_token,
        refresh_expires_at: action.payload.refresh_expires_at,
        expires_at: action.payload.expires_at,
        encoded_cz_id: action.payload.encoded_cz_id,
      };
    },
    removeUserData: (state) => {
      state.user = {
        id: '',
        visitor_id: '' || visitor_id,
        authentication_token: '',
        access_token: '',
        loading: false,
        errorMessage: null,
        type: '' || type,
        refresh_token: '',
        refresh_expires_at: '',
        expires_at: '',
        encoded_cz_id: null,
      };
    },
  },
});

export const { updateUserData, removeUserData } = userSlice.actions;

export const userSliceReducer = userSlice.reducer;
