import React from 'react';
import { Modal } from 'react-bootstrap';

import { IAddAndRemoveModalProps } from '../../../models/try2Combo';
import { itemBuckets } from '../../../priceCalculation/buckets';
import { resetMadedTryTwoComboItemData } from '../../../redux/slices/itemCustomizationSlice';
import { useAppDispatch } from '../../../redux/store/store';

const AddAndRemoveModal: React.FunctionComponent<IAddAndRemoveModalProps> = (
  props,
) => {
  const {
    showAddAndRemoveModal,
    hideAddAndRemoveModal,
    id,
    name,
    onRemoveClickHandler,
    onEditClickHandler,
    isCustomizeable,
  } = props;
  const dispatchItemCustomization = useAppDispatch();
  const clickHanlder = () => {
    dispatchItemCustomization(resetMadedTryTwoComboItemData());
    onRemoveClickHandler(id);
    itemBuckets.resetSpecificComboBucket(id);
    hideAddAndRemoveModal();
    // dispatch(removeSpecificItem({name:id}))
  };
  const clickEditHanlder = () => {
    onEditClickHandler(name, id);
    hideAddAndRemoveModal();
    // dispatch(removeSpecificItem({name:id}))
  };
  return (
    <Modal
      show={showAddAndRemoveModal}
      onHide={hideAddAndRemoveModal}
      centered
      className="theme-modal-style app_container_wrap no-padding border-radius-15 border-radius-sm-8 "
    >
      <Modal.Header className="pt-0" id="close_button2">
        <button
          type="button"
          className="btn modal-close-button pe-0 blue-icon"
          onClick={hideAddAndRemoveModal}
        >
          Close
        </button>
      </Modal.Header>
      <Modal.Body className="text-center py-5">
        <h6 className="font-Cls ls-normal mb-sm-4 mb-3 f-s22">
          Edit or Remove
        </h6>
        <p className="f-s14 mb-4">
          Would you like to edit or remove
          <br />
          this selected item?
        </p>
        <div className="text-center">
          <button
            type="button"
            className="btn-large mb-sm-4 mb-3 d-block mx-auto"
            onClick={clickEditHanlder}
            disabled={isCustomizeable}
          >
            Edit
          </button>
          <button
            type="button"
            className="btn f-s16 font-Vcf clr-dark-green p-0"
            onClick={clickHanlder}
          >
            Remove
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddAndRemoveModal;
