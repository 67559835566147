import React, { useEffect } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import {
  DELIVERY_DESTINATION_ID,
  IN_STORE_DESTINATION_ID,
  s3BaseUrl,
  DELIVERY_ORDER_TYPE,
} from '../../../constants';
import { ISelectedItemDetailsForModal } from '../../../models/try2Combo';
import { setShowCart } from '../../../redux/slices/cartSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store/store';

import styleClasses from './order.module.scss';
import useGetUpsellItemsForAddToOrder from 'src/react-query-hooks/useGetUpsellItemsForAddToOrder';
import UpsellItemCard from 'src/Features/Cart/UpsellItems/UpsellItemCard';

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  name: string;
  image: string;
  comboItems?: ISelectedItemDetailsForModal[];
  styleTitle?: string;
  styleName?: string;
  isSingleItem?: boolean;
  isCombo?: boolean;
  item?: any;
  itemFromApi?: any;
  setshowAddtoOrderModal?: any;
}
const AddedModal: React.FunctionComponent<ModalProps> = (props) => {
  const {
    showModal,
    closeModal,
    name,
    image,
    comboItems,
    styleTitle,
    styleName,
    isSingleItem,
    isCombo,
    item,
    itemFromApi,
    setshowAddtoOrderModal,
  } = props;
  const dispatch = useAppDispatch();
  // const { locationInfo } = useStoreLocation();
  const locationInfo = useAppSelector((state) => state.location);
  const history = useHistory();

  const location_id = locationInfo.selectedStore?.id;

  const { search } = useLocation();

  const cart = useAppSelector((state) => state.cart);
  const query = new URLSearchParams(search);

  const category_id = query.get('category_id');

  const itemImage = comboItems ? (
    <div className=" mb-3 pt-2 pb-2 row">
      {comboItems?.map((item) => {
        return item?.is_selected ? (
          <div
            className={`${
              isSingleItem ? 'd-flex flex-column align-items-center' : 'col-6'
            }`}
          >
            <div
              className={`${styleClasses.imgWrapper} d-flex align-items-center`}
            >
              <img
                src={`${s3BaseUrl}${item.image}?version=1`}
                className={`mb-1 ${styleClasses.img_fluid_border}`}
                alt="icon"
              />
            </div>
            <p className="text-center font-rale mt-2 fw-500 f-s16 f-sm-s14">
              {' '}
              {item?.itemName}
            </p>
          </div>
        ) : null;
      })}
    </div>
  ) : (
    <img src={image} className="img-fluid" alt="menu_image" />
  );

  const {
    data: upselData,
    refetch: fetchUpsell,
    isFetching: isFetchingUpsell,
    isLoading: isUpsellLoading,
  } = useGetUpsellItemsForAddToOrder({
    location_id,
    category_id: category_id,
    order_type:
      cart?.orderType === DELIVERY_ORDER_TYPE
        ? DELIVERY_DESTINATION_ID
        : IN_STORE_DESTINATION_ID,
  });

  const onViewCartClick = () => {
    window.location.reload();
    sessionStorage.setItem('recently_item_added_to_cart', '1');
  };

  const upSellItems =
    upselData?.length > 0 ? (
      <>
        <h4 className="f-s22 text-center font-Cls  lh-30 f-sm-s16">
          {upselData[0].header}
        </h4>
        <Row className={`${styleClasses.suggestion_row} mb-3`}>
          {upselData?.slice(0, 3)?.map((item, index: number) => (
            <>
              <UpsellItemCard
                key={item.id}
                upSellItem={item}
                btnText={'Add'}
                cardGridSpacing={'px-1'}
                setshowModal={setshowAddtoOrderModal}
              />
            </>
          ))}
        </Row>
      </>
    ) : null;

  return (
    <>
      <Modal
        show={showModal}
        centered
        className={`theme-modal-style app_container_wrap xs-modal border-radius-15 align-sm-bottom ${styleClasses.addedModalSize}`}
      >
        <Modal.Header className="pt-0"></Modal.Header>
        <Modal.Body className="px-0 pb-5 pt-3">
          <div className="d-flex flex-column h-100 no-shadow">
            <div className={`${styleClasses.item_title_wrap} menu-title`}>
              <h6 className={`sub_title1 text-center text-uppercase f-s22`}>
                Added to your cart!
              </h6>
            </div>

            <div
              className={`${styleClasses.item_calor_wrap} menu-calories d-flex justify-content-center`}
            >
              <p
                className={`f-s18 clr-dark-grey font-Visby-cf f-w6  text-center pt-1 ${styleName}`}
              >
                {name}
              </p>
            </div>
            <hr className="my-2" />
            <div className="px-md-5 pt-0">{itemImage}</div>
            {upSellItems}

            <Link to={`/menu/${item}`}>
              <div
                className={` btn-large position-relative py-2 btn-disabled-bg w-100  ${styleClasses.added_modal_btn}`}
              >
                Continue Ordering
              </div>
            </Link>
            <button
              type="button"
              className={`btn-large outline mt-2 py-2 w-100 ${styleClasses.added_modal_btn}`}
              onClick={() => onViewCartClick()}
            >
              View Cart
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddedModal;
