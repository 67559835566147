import { IPayHouseAccountBalance } from 'src/models/houseAccount.modal';
import { IEmailPayload, IIDPayload } from 'src/models/misc';

import * as CONSTANT from '../constants';
import { CUSTOMER_MICRO_SERVICE } from '../constants';
import {
  DeliveryAddressFromFields,
  IAddRewardToDiscountPayload,
  IForgotPasswordPayload,
  SignupFormFields,
} from '../models/forms.model';
import { IAddBarcodePayload } from '../models/rewards.model';

import { HttpService } from './base.service';

interface vehicleInfoProps {
  make: string;
  model: string;
  color: string;
}
class CustomerBusinessService extends HttpService {
  baseURL = CUSTOMER_MICRO_SERVICE;
  login = (body: any): Promise<any> => this.post(`catering/login`, body);
  guestLogin = (body: any): Promise<any> =>
    this.post(`catering/guest-customer`, body);
  createAccount = (body: SignupFormFields): Promise<any> =>
    this.post(`catering/customers`, body);
  changePassword = (body: any): Promise<any> =>
    this.patch(`catering/change-password`, body);
  verifyLink = (body: any): Promise<any> =>
    this.post(`catering/verify-link?is_catering=1`, body);
  verifyEmailLink = (body: any): Promise<any> =>
    this.post('verify-email?is_catering=1', body);
  resendVerificationEmail = (body: IEmailPayload): Promise<any> =>
    this.post(`resend-verify-email?is_catering=1`, body);
  resetPassword = (body: IEmailPayload): Promise<any> =>
    this.post(`reset-link?is_catering=1`, body);

  forgotPassword = (body: IForgotPasswordPayload): Promise<any> =>
    this.post(`catering/forgot-password?is_catering=1`, body);
  addRewardToDiscount = (body: IAddRewardToDiscountPayload): Promise<any> =>
    this.post('addToDiscountApplied', body);
  getMyRewards = (body: any): Promise<any> => this.get(`getMyRewards`, body);
  locationAddToFavorites = (data: {
    customer_id: number;
    locations: number[];
  }): Promise<any> => this.post(`locations`, data);
  removeFavoritesLocation = (
    location_id: string,
    userId: string | number,
  ): Promise<any> => this.delete(`locations/${location_id}/${userId}`);
  addMyRewards = (body: any): Promise<any> => this.post('addMyRewards', body);
  addAddress = (deliveryAddress: DeliveryAddressFromFields): Promise<any> =>
    this.post('addresses', deliveryAddress);
  updateAddress = (addressId: number, body: any): Promise<any> =>
    this.put(`addresses/${addressId}`, body);
  removeAddress = (addressId: number): Promise<any> =>
    this.delete(`addresses/${addressId}`);
  updateCustomerProfile = (body: any): Promise<any> =>
    this.put(`customers`, body);
  getCustomerProfile = (userId: number | string, body: any): Promise<any> =>
    this.get(`customers/${userId}`, body);
  getCustomerRewards = (id: string): Promise<any> =>
    this.get(`customer/rewards/${id}`);
  removeVehicle = (vehicleId: number): Promise<any> =>
    this.delete(`vehicle-info/${vehicleId}`);
  addVehicleInformation = (body: vehicleInfoProps): Promise<any> =>
    this.post('vehicle-info', body);
  updateVehicleDetails = (
    vehicleId: number | string,
    body: any,
  ): Promise<any> => this.put(`vehicle-info/${vehicleId}`, body);
  getVehicles = (): Promise<any> => this.get(`vehicles`);
  addBarcode = (body: IAddBarcodePayload): Promise<any> =>
    this.post(`loyalty-checkin-by-barcode`, body);
  deleteUser = (userId: number | string): Promise<any> =>
    this.delete(`customers/${userId}`);
  googleSignIn = (payload: any): Promise<any> =>
    this.post(`${CONSTANT.GOOGLE_SIGNIN}`, payload);
  facebookSignIn = (payload: any): Promise<any> =>
    this.post(`${CONSTANT.FACEBOOK_SIGNIN}`, payload);
  appleSignIn = (payload: any): Promise<any> =>
    this.post(`${CONSTANT.APPLE_SIGNIN}`, payload);
  // tax exempt
  getTaxExemptAccounts = (): Promise<any> =>
    this.get('customer/tax-exempt-accounts?customer_status=1');

  // house accounts
  getHouseAccountsList = (data): Promise<any> =>
    this.get(`customer/house-accounts?is_active=1&all_accounts=${data}`);
  getHouseAccountsDetail = (id: number): Promise<any> =>
    this.get(`house-accounts/detail/${id}`);
  getHouseAccountCustomersList = (id: number): Promise<any> =>
    this.get(`house-accounts/customers/${id}`, { size: 50 });
  getHouseAccountTransactionsFile = (id: string): Promise<any> =>
    this.getFile(`house-accounts/transactions/${id}`);
  getHouseAccountRecentTransactions = (id: number): Promise<any> =>
    this.get(`house-accounts/recent-top-ups/${id}`);
  payHouseAccountBalance = (data: IPayHouseAccountBalance) =>
    this.post(`house-accounts/top-up`, data);

  getUserProfileStatus = (id: number): Promise<any> =>
    this.get(`customers/profile-status/${id}`);
  getOffers = (params: any): Promise<any> => this.get(`loyalty/offers`, params);
  getCZDollarsSharedAccount = (): Promise<any> =>
    this.get(`catering-loyalty/cz-dollars/share-list`);
  shareCZDollarsWithEmail = (payload: IEmailPayload): Promise<any> =>
    this.post(`catering-loyalty/cz-dollars/share`, payload);
  unlinkCZDollarsSharedAccount = (payload: IIDPayload): Promise<any> =>
    this.patch(`catering-loyalty/cz-dollars/unlink`, payload);
  resendCZDollarsInvitation = (payload: IEmailPayload): Promise<any> =>
    this.post(`catering-loyalty/cz-dollars/resend-invitation`, payload);
  applyPromoCode = (body): Promise<any> =>
    this.get(`loyalty/apply-promo`, body);
  updatePassword = (body): Promise<any> =>
    this.patch('customers/update-password', body);
  subscribeEmail = (body: any): Promise<any> =>
    this.post('email-subscription', body);

  getCZDollarsBalance = (): Promise<any> =>
    this.get(`catering-loyalty/cz-dollars/balance`);
  getVisaCashBackInfo = (): Promise<any> =>
    this.get(`catering-loyalty/visa-cashback`);
  getUserPointsEarned = (): Promise<any> =>
    this.get(`catering-loyalty/user-points`);
  getTiersDetails = (): Promise<any> =>
    this.get(`catering-loyalty/tier-details`);
  getGiftCardNumber = (): Promise<any> =>
    this.get(`customer-gift-cards?is_cz=1`);
  triggerCampaign = (data: any): Promise<any> =>
    this.post('loyalty/link-campaign-trigger', data);
}

export const customerBusinessService = new CustomerBusinessService();
