import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';
import useTriggerCampaign from '../react-query-hooks/useTriggerCampaign';
import { ROUTE_LOGIN } from '../constants';
import { useAppSelector } from 'src/redux/store/store';
import { isSignedInUser } from 'src/helper/helperMethods';
import { Toast_Func } from 'src/helper/toast.helper';

const useCampaignTrigger = () => {
  const location = useLocation();
  const history = useHistory();

  const [params, setParams] = useState({});

  const { user } = useAppSelector((state) => state.user);

  const isThisMobile = useCheckMobileScreen();

  const searchParams = new URLSearchParams(window.location.search);

  const { data: userOfferResponse, isFetching } = useTriggerCampaign(
    {
      ...params,
      logged_in_user: isSignedInUser(user) ? user?.encoded_cz_id : null,
      source: isThisMobile ? 'Mobile Web' : 'Web',
    },
    Boolean(searchParams.get('action') && Object.keys(params).length),
  );

  useEffect(() => {
    if (!searchParams.get('action')) return;
    const paramsObject = {};

    // Iterate through all parameters and store them in an object
    searchParams.forEach((value, key) => {
      paramsObject[key] = value;
    });

    setParams(paramsObject);
  }, []);

  useEffect(() => {
    if (!searchParams.get('action')) return;
    if (userOfferResponse) {
      searchParams.delete('action');
      // Replace the URL with the updated one (without reloading the page)
      history.replace(
        {
          pathname: location.pathname, // Keep the path the same
          search: searchParams.toString(), // Empty string means no query params
        },
        { replace: true },
      );
    }
    if (userOfferResponse?.code === 200 && userOfferResponse.show_toast) {
      Toast_Func({ status: true, message: userOfferResponse.message });
    }
    if (userOfferResponse && !isSignedInUser(user)) {
      history.push(ROUTE_LOGIN);
    }
  }, [userOfferResponse]);

  return { data: userOfferResponse, loading: isFetching };
};

export default useCampaignTrigger;
