import { useEffect } from 'react';
import { EditPencilIcon } from 'src/assets/images/Svgs/svg';
import CustomModal from 'src/components/customModal/CustomModal';
import { getAddPaymentUser, getUser } from 'src/helper/helperMethods';
import { useAppSelector } from 'src/redux/store/store';

import styles from '../../../../src/Features/AccountSetup/account.module.scss';
import ContactInfoForm from '../../../components/Forms/ContactInfoForm';
import { useLocation } from 'react-router';
import { ROUTE_ADD_PAYMENT, ROUTE_REQUEST_PAYMENT } from 'src/constants';

interface IContactMethodProps {
  isOrdered?: boolean;
  order?: any;
  contactTitle?: string;
  titleStyle?: string;
  contactInfoMessage?: string;
  setContactInfoMessage?: (string) => void;
  showContactForm?: boolean;
  handleShowContactForm?: () => void;
  handleCloseContactForm?: () => void;
  hideEditButton?: boolean;
}

const ContactMethod = (props: IContactMethodProps) => {
  const {
    isOrdered,
    contactInfoMessage,
    setContactInfoMessage,
    order,
    titleStyle,
    showContactForm,
    handleShowContactForm,
    handleCloseContactForm,
    contactTitle,
    hideEditButton,
  } = props;
  const location = useLocation();
  const handlePaymentForAddPaymentUser =
    location.pathname.includes(ROUTE_ADD_PAYMENT) ||
    location?.pathname.includes(ROUTE_REQUEST_PAYMENT);
  const authInfo = handlePaymentForAddPaymentUser
    ? useAppSelector((state) => state.addPayment.addPaymentUser)
    : useAppSelector((state) => state.user.user);
  const user = handlePaymentForAddPaymentUser
    ? getAddPaymentUser().customer
    : getUser();

  const { first_name, last_name, email, phone } = user;
  const isContactInfoComplete = first_name && last_name && email && phone;

  const currentUser = {
    first_name,
    last_name,
    email,
    phone,
  };

  useEffect(() => {
    if (isContactInfoComplete) setContactInfoMessage?.('');
  }, [user]);

  const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    const cleaned = ('' + str).replace(/\D/g, '');
    //Check if the input is of correct length
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h4
          className={`f-s20 text-start cz-lh-24 text-uppercase font-Cls mb-0 fw-normal f-sm-s16 f-w5 mb-2 ${titleStyle}`}
        >
          {contactTitle}
        </h4>
        {(authInfo.id ||
          currentUser.email ||
          currentUser.first_name ||
          currentUser.phone) && (
          <button
            className={`${
              isOrdered || hideEditButton ? 'd-none' : null
            } btn d-md-none f-s16 text_underline clr-text-grey ms-3 p-0`}
            onClick={handleShowContactForm}
          >
            <EditPencilIcon />
          </button>
        )}
      </div>

      <div className="d-flex justify-content-between align-items-center pb-md-0 pb-4">
        <div>
          <span className={'clr-dark-green f-s14 d-flex'}>
            {contactInfoMessage}
          </span>
          <span className="d-block text-start font-Visby-cf f-s16 fw-bold f-sm-s14 f-sm-w5 mt-0">
            {isOrdered
              ? `${order?.customer?.first_name} ${order?.customer?.last_name}`
              : `${currentUser?.first_name} ${currentUser?.last_name}`}
          </span>
          <span className="d-block text-start font-rale f-s16 f-w5 mb-2 f-sm-s14 f-sm-w5">
            {isOrdered ? order?.customer?.email : currentUser?.email}
          </span>
          <span className="d-block text-start font-rale f-s16 f-w5 mb-2 f-sm-s14 f-sm-w5">
            {formatPhoneNumber(
              isOrdered ? order?.customer?.phone : currentUser?.phone,
            )}
          </span>
        </div>
        <div className={isOrdered ? 'd-none' : null}>
          {(authInfo.id ||
            currentUser.email ||
            currentUser.first_name ||
            currentUser.phone) &&
            !hideEditButton && (
              <button
                className={`btn  f-s16 text-decoration-underline clr-text-grey ms-3 p-0 d-none d-md-block f-w6`}
                onClick={handleShowContactForm}
              >
                <span className=""> Edit </span>
              </button>
            )}
        </div>
      </div>

      {!authInfo.id &&
        !currentUser.email &&
        !currentUser.first_name &&
        !currentUser.phone && (
          <div className="d-flex justify-content-center justify-content-md-start mb-md-0 mb-4 col-md-6 ">
            <button
              type="button"
              className="btn btn-large outline mt-md-3 mt-0 w-100"
              onClick={handleShowContactForm}
            >
              Add contact info
            </button>
          </div>
        )}
      <CustomModal
        showModal={showContactForm}
        closeModal={handleCloseContactForm}
        title={`Add Contact Info`}
        titletextAlign={'text-center w-100'}
        modalDialogClasses={`${styles.taxExemptionModal} ${styles.contactInfoModal}`}
      >
        <ContactInfoForm
          closeModal={handleCloseContactForm}
          currentGuestUserInfo={currentUser}
        />
      </CustomModal>
    </>
  );
};

export default ContactMethod;
