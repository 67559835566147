import React from 'react';
import { Modal } from 'react-bootstrap';

interface ItemRemoveModalProps {
  showModal: boolean;
  loading: boolean;
  handleClose: () => void;
  removeItem: () => void;
}
const ItemRemoveModal: React.FC<ItemRemoveModalProps> = ({
  showModal,
  handleClose,
  removeItem,
  loading,
}) => {
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className="theme-modal-style app_container_wrap no-padding border-radius-8 no-border"
      >
        <Modal.Header className="pt-0" id="close_button2">
          <button
            type="button"
            className="btn border-0 modal-close-button pe-0 blue-icon"
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Header>
        <Modal.Body className="text-center py-5">
          <h6 className="font-Cls ls-normal mb-sm-4 mb-3 f-s22">Remove Item</h6>
          <p className="f-s14 mb-4">
            Are you sure you want to remove this item
            <br />
            from your order?
          </p>
          <div className="text-center">
            <button
              type="button"
              className=" btn-large mb-sm-4 mb-2 d-block mx-auto"
              onClick={removeItem}
              id={'remove_item_button'}
              disabled={loading}
            >
              Remove
            </button>
            <button
              type="button"
              onClick={handleClose}
              className="btn btn-custom f-s16 font-Vcf clr-dark-green p-0 ls-05"
            >
              No, Keep the Order
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ItemRemoveModal;
