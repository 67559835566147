import React, { useEffect } from 'react';

interface ISelectedPaymentsProps {
  allSelectedCards?: any[];
  paymentValidationMessage?: string;
  setPaymentValidationMessage?: (string) => void;
  checkout?: any;
  disabled?: boolean;
}
const SelectedPayments: React.FunctionComponent<ISelectedPaymentsProps> = (
  props: ISelectedPaymentsProps,
) => {
  const {
    allSelectedCards,
    paymentValidationMessage,
    setPaymentValidationMessage,
    checkout,
    disabled,
  } = props;
  useEffect(() => {
    if (allSelectedCards.length > 0 && !checkout.isSplitPayment)
      setPaymentValidationMessage('');
  }, [allSelectedCards]);
  return (
    <>
      <div className="pb-3">
        <span className={'clr-dark-green f-s14 d-flex'}>
          {paymentValidationMessage}
        </span>
        <div>
          {allSelectedCards?.map((payment) => {
            return (
              <React.Fragment key={payment.id}>
                <span
                  className={`d-block text-start font-rale f-s16 f-w7 f-sm-s14 f-sm-w5 ${
                    disabled && 'opacity-75'
                  }`}
                >
                  {payment.name}
                </span>
                <div className="d-flex justify-content-between">
                  <span
                    className={`${
                      disabled && 'opacity-75'
                    } paymentMethod === CONSTANT.PAYMENT_HOUSE_ACCOUNTd-block text-start font-rale f-s16 f-w5 mb-2 f-sm-s14`}
                  >
                    {payment?.number}
                  </span>
                  <span
                    className={`${
                      disabled && 'opacity-75'
                    } sd-block text-start font-rale f-s16 f-w5 mb-2 f-sm-s14`}
                  >
                    {checkout?.isSplitPayment ? `$ ${payment.amount}` : ''}
                  </span>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SelectedPayments;
