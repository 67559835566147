import { useQuery } from 'react-query';
import { GUEST_USER } from 'src/constants';
import { ItemDetails } from 'src/models/item.model';

import { locationMenuService } from '../services';

interface IQueryParams {
  location_id: string;
  category_id: string;
  item_id: string;
  user?: IAuthInfo;
}
interface IPayload {
  location_id: string;
  category_id: string;
  item_id: string;
  customer_id?: number;
}

interface IAuthInfo {
  id: number;
  type: string | null;
}

const getItem = async ({
  location_id,
  category_id,
  item_id,
  user,
}: IQueryParams) => {
  const payload: IPayload = {
    location_id,
    category_id,
    item_id,
  };
  if (user.type !== GUEST_USER) payload.customer_id = user?.id;
  const response = await locationMenuService.getLocationMenuItem(payload);
  const category = response?.data?.data?.categories[0];
  const item = response?.data?.data?.categories[0]?.items[0];
  item.basePrice = category?.base;
  const itemModifierGroups = item.items_required_modifier_groups.map(
    (group) => ({
      ...group,
      required_modifiers_groups_modifiers:
        group.required_modifiers_groups_modifiers.sort(
          (a, b) => Number(b.in_item) - Number(a.in_item),
        ),
    }),
  );
  return {
    ...item,
    itemModifierGroups,
  };
};

export default function useItem(queryParams: IQueryParams) {
  const isEnabled = !!(
    queryParams.category_id &&
    queryParams.item_id &&
    queryParams.location_id
  );

  return useQuery(['item', queryParams.item_id], () => getItem(queryParams), {
    staleTime: 0,
    cacheTime: 0,
    enabled: isEnabled,
  });
}
