import { NavLink } from 'react-router-dom';
import {
  DELIVERY_ORDER_TYPE,
  GUEST_USER,
  INDIVIDUAL_CAFEZUPAS_DEV_ROUTE,
  INDIVIDUAL_CAFEZUPAS_ROUTE,
  ROUTE_LOGIN,
} from 'src/constants';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import { useAppSelector } from 'src/redux/store/store';
import styles from './header.module.scss';

interface TopHeaderBarProps {
  handleLocationButton: () => void;
  locationLabel: any;
}
const TopHeaderBar: React.FC<TopHeaderBarProps> = ({
  handleLocationButton,
  locationLabel,
}) => {
  const { type } = useAppSelector((state) => state.user.user);
  const isGuestUser = type !== GUEST_USER;
  const Cart = useAppSelector((state) => state.cart);
  const getBaseUrl = () => {
    return import.meta.env.VITE_REACT_APP_ENV === 'production'
      ? INDIVIDUAL_CAFEZUPAS_ROUTE
      : INDIVIDUAL_CAFEZUPAS_DEV_ROUTE;
  };

  return (
    <div className={`${styles.topHeaderWrapper}`}>
      <div className="w-100">
        <div className="d-flex align-items-center mt-1 mt-md-0 justify-content-md-start justify-content-between w-100">
          <div className={styles.switcher}>
            <a href={getBaseUrl()} className={styles.switcherLink}>
              {' '}
              INDIVIDUAL SITE
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
              >
                <g clipPath="url(#clip0_7565_158)">
                  <path
                    d="M7.33331 16H26"
                    stroke={'#0956a8'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.6666 21.3333L26 16"
                    stroke={'#0956a8'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.6666 10.6667L26 16.0001"
                    stroke={'#0956a8'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7565_158">
                    <rect
                      width="32"
                      height="32"
                      fill="white"
                      transform="translate(0.666626)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>

          <div className="d-md-none ">
            <button
              type="button"
              className={`${
                styles.locationNavSection +
                ' ' +
                (Cart.orderType === DELIVERY_ORDER_TYPE
                  ? styles.online_location_icon
                  : '')
              } d-flex p-0 justify-content-between ms-auto align-items-center`}
              onClick={handleLocationButton}
            >
              {locationLabel()}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeaderBar;
