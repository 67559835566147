import { cloneDeep } from 'lodash';
import _clonedeep from 'lodash.clonedeep';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import AddedModal from 'src/Features/BoxLunches/AddedModal/AddedModal';
import BoxLunches from 'src/Features/BoxLunches/BoxLunches';
import Loader from 'src/components/Loader/Loader';
import * as CONSTANT from 'src/constants';
import { finalOrder } from 'src/helper/boxLunch';
import {
  availableForSelectedOrderType,
  availableModifiers,
  checkIfUserAddSecondItemAfterOne,
  createPayloadToSync,
  processEditItemModifiers,
  sortBasedOnPriceByModifierGroups,
  unavailableModifiers,
} from 'src/helper/customizedItem/customizedItem.helper';
import {
  getOrderButtonState,
  getUser,
  getVisitorId,
  isItCreateYourOwnItem,
} from 'src/helper/helperMethods';
import { Toast_Func } from 'src/helper/toast.helper';
import useAddToOrder from 'src/hooks/useAddToOrder';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import {
  INoDressingModifier,
  ItemDetailForm,
  ItemDetails,
  RouteState,
} from 'src/models/item.model';
import { IGetItemFromCartPayload } from 'src/models/order.model';
import {
  ISelectedItem,
  ISelectedItemDetailsForModal,
  ISelectedModifier,
} from 'src/models/try2Combo';
import { itemBuckets } from 'src/priceCalculation/buckets';
import { calculatePriceForItem } from 'src/priceCalculation/calculatePrice';
import { modifiersAgainstAnItem } from 'src/priceCalculation/helper';
import useGetCartItem from 'src/react-query-hooks/Cart/useGetCartItem';
import useItem from 'src/react-query-hooks/useItem';
import {
  addAddChoseForMeSize,
  addTry2ComboItem,
  editComboItems,
  removeTemporarilyAddedPriceForItem,
  removeSpecificItem,
  removeTry2ComboIngredients,
  resetChooseForMe,
  resetContext,
  resetTry2ComboContext,
  swapTry2ComboContext,
  try2ComboItemSelection,
  updateModifiers,
  updatePriceForAllItem,
  updatePriceForItem,
  updateSpecificItemSelectedModifiers,
} from 'src/redux/slices/itemCustomizationSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store/store';

import ItemDetail from '../itemDetail';
import styles from '../itemDetails.module.scss';
import EventBusForBYOCategoryScrollingBetweenAccordions from 'src/utils/EventBusForBYOCategoryScrollingBetweenAccordions';
import { setShowCart } from 'src/redux/slices/cartSlice';

const BoxLunchesContainer = () => {
  const locationInfo = useAppSelector((state) => state.location);
  const orderType = useAppSelector((state: any) => state.cart.orderType);
  const { search, state } = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const query = new URLSearchParams(search);
  const category_id = query.get('category_id');
  const { id: item_id } = useParams<any>();
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [chooseForMe, setChooseForMe] = useState<boolean>(false);
  const [selectedItemCount, setSelectedItemCount] = useState<string>(
    CONSTANT.COMBO_ITEM_ONE,
  );
  const [
    isShowingRequiredModalOnCustomization,
    setIsShowingRequiredModalOnCustomization,
  ] = useState<boolean>(false);
  const autoScrollDivRef = useRef<HTMLDivElement>(null);
  const subscribeEvent = useRef<boolean>();
  const [ingredientsModal, setIngredientsModal] = useState<boolean>(false);
  const [try2ComboItemDetails, setTry2ComboItemDetails] = useState<
    ISelectedItemDetailsForModal[]
  >([
    { id: '1', itemName: null, image: null, is_selected: false },
    { id: '2', itemName: null, image: null, is_selected: false },
  ]);
  const [addedItemInTry2Combo, setAddedItemInTry2Combo] = useState<string[]>(
    [],
  );

  const [formState, setFormState] = useState<ItemDetailForm>({
    quantity: 1,
    name: [],
    instructions: '',
    reset: false,
  });
  const { addToOrder, loading } = useAddToOrder();

  const [boxLunchCombo, setBoxLunchCombo] = useState<
    { id: string; title: string; sm_title: string }[]
  >(CONSTANT.BOXLUNCH);
  const isThisMobile = useCheckMobileScreen();
  const dispatchItemCustomization = useDispatch();
  const [sectionSelected, setSectionSelected] = useState('1');
  const [requiredModalShow, setRequiredModalShow] = useState<boolean>(false);
  const [callRadioChange, setCallRadioChange] = useState(false);
  const [successModalShow, setSuccessModalShow] = useState<boolean>(false);
  const [routerState, setRouterState] = useState<RouteState>({});
  const [editfromDetails, setEditFromDetails] = useState<boolean>(false);
  const [loadValueForModal, setLoadValueForModal] = useState<{
    load: boolean;
    name: string;
    id: string;
  }>(null);
  const [editIngredientModal, setEditIngredientModal] = useState(false);
  const showCustomization =
    (state as { showCustomization?: boolean })?.showCustomization ?? false;
  const isSingleItem =
    (state as { isSingleItem?: boolean })?.isSingleItem ?? false;
  const isBoxLunch = query.get('is_box_lunch');
  const order = useAppSelector((state: any) => state.itemCustomization);
  useEffect(() => {
    ingredientsModal && setRequiredModalShow(false);
  }, [ingredientsModal]);

  const togglePersonalize = (): void => {
    setChooseForMe(false);
    setPersonalize(!personalize);
    // if (!isEitherItemSelect()) {
    //   dispatchItemCustomization(test({ price: item?.price }));
    // }
  };

  const dbId = query.get('id');
  const user = getUser();
  const location_id = locationInfo.selectedStore?.id;
  const payload: IGetItemFromCartPayload = {
    id: dbId,
    location_id,
  };
  if (user.id) payload.customer_id = user.id;
  else payload.visitor_id = getVisitorId();

  const [itemDetails, setItemDetails] = useState<boolean>(true);
  const [personalize, setPersonalize] = useState(false);

  const dispatch = useAppDispatch();

  const hideCustomizationModalHandler = () => {
    setRequiredModalShow(false);
  };
  const toggleIngrediatsModal = (state: boolean) => {
    setIngredientsModal(state);
  };
  const {
    data: item,
    isFetching,
    refetch: refetchItem,
    isError: isUseItemError,
    error: useItemError,
  } = useItem({
    item_id: item_id,
    location_id: location_id,
    category_id: category_id,
    user,
  });
  const {
    data: editCartItem,
    refetch: fetchItem,
    isFetching: editItemFetching,
    isError,
  } = useGetCartItem(payload);

  const [isCreateYourOwnItem, setIsCreateYourOwnItem] = useState(
    isItCreateYourOwnItem(
      selectedItem
        ? selectedItem?.is_create_your_own
        : editCartItem?.is_create_your_own,
    ),
  );

  const { requiredModifiersAtItemDetailSectionToHandleScroll } =
    useScrollToMissingRequiredArea(item);

  const recentlyDeletedId = useAppSelector(
    (state: any) => state.cart.recentlyDeletedId,
  );

  const checkForMisssingRequiredModifierGroups = () => {
    const itemToDispatchToEvent =
      item &&
      editCartItem &&
      item.sub_categories[0].items.find(
        (currentItem) =>
          currentItem.id ===
          editCartItem.modifiers.find(
            (currentItemCartItem) => currentItemCartItem.is_create_your_own,
          )?.modifier_id,
      );
    const requiredModifierSelection: {
      status: boolean;
      missingGroupIndex?: number;
    } = getOrderButtonState({
      type: CONSTANT.ADD_TO_ORDER,
      item: selectedItem ? selectedItem : itemToDispatchToEvent,
      order,
      orderType,
    });
    return requiredModifierSelection;
  };

  useEffect(() => {
    if (dbId) {
      setSelectedItem(null);
      EventBusForBYOCategoryScrollingBetweenAccordions.unsubscribe(
        'checkForMissingRequiredModifers',
        checkForMisssingRequiredModifierGroups,
      );
      subscribeEvent.current = true;
    }
  }, [dbId]);

  useEffect(() => {
    if (!dbId || !subscribeEvent.current) return;
    const shouldSubscribeEvent = selectedItem
      ? selectedItem
      : item &&
        editCartItem &&
        editCartItem.is_create_your_own &&
        Boolean(dbId == editCartItem.id);
    if (shouldSubscribeEvent && order.size) {
      subscribeEvent.current = false;
      EventBusForBYOCategoryScrollingBetweenAccordions.subscribe(
        'checkForMissingRequiredModifers',
        checkForMisssingRequiredModifierGroups,
      );
    }
  }, [dbId, item, editCartItem, order.size]);

  useEffect(() => {
    if (selectedItem || (dbId && editCartItem)) {
      setIsCreateYourOwnItem(
        isItCreateYourOwnItem(
          selectedItem
            ? selectedItem?.is_create_your_own
            : editCartItem?.is_create_your_own,
        ),
      );
    }
  }, [selectedItem, editCartItem]);

  useEffect(() => {
    if (!showCustomization || !dbId) return;
    if (recentlyDeletedId === Number(dbId) || isError) {
      Toast_Func({ message: 'This Item does not exist!', status: false });
      history.push('/menu');
    }
  }, [recentlyDeletedId, isError]);

  const openCartAfterAddingAndItem = () => {
    dispatch(setShowCart(true));
    sessionStorage.setItem('recently_item_added_to_cart', '0');
  };

  useEffect(() => {
    /* Handle View Cart Click To Open Cart On Page Reload @param = recently_item_added_to_cart*/
    const isOpenCart = sessionStorage.getItem('recently_item_added_to_cart');
    parseInt(isOpenCart) && openCartAfterAddingAndItem();

    return () => {
      itemBuckets.resetBuckets();
      setFormState({
        quantity: 1,
        name: [],
        instructions: '',
        reset: true,
      });
      dispatchItemCustomization(resetContext());
    };
  }, []);

  useEffect(() => {
    if (selectedItem && subscribeEvent.current) {
      EventBusForBYOCategoryScrollingBetweenAccordions.subscribe(
        'checkForMissingRequiredModifers',
        checkForMisssingRequiredModifierGroups,
      );
      subscribeEvent.current = false;
    }
  }, [selectedItem]);

  useEffect(() => {
    if (item && (!item.is_box_lunch || isBoxLunch !== 'true')) {
      history.push(CONSTANT.ROUTE_MENU);
    }
  }, [item]);

  useEffect(() => {
    if (showCustomization && dbId) {
      personalize && setPersonalize(false);
      if (isSingleItem) {
        itemBuckets.resetBuckets();
        setBoxLunchCombo(CONSTANT.SINGLEITEM_BOXLUNCH);
      } else {
        itemBuckets.resetBuckets();
        setBoxLunchCombo(CONSTANT.BOXLUNCH);
      }
      dispatchItemCustomization(resetContext());
      itemBuckets.createComboBucket({
        keys: [
          CONSTANT.REQUIRED_MODIFIERS,
          CONSTANT.CORE_MODIFIERS,
          CONSTANT.ADD_ONS,
          CONSTANT.COMPLIMENTARY_MODIFIER,
        ],
        items: isSingleItem ? 1 : 2,
        handleItemOwnModifiers: true,
      });
      fetchItem();
    }
  }, [showCustomization, dbId]);

  useEffect(() => {
    if (
      editCartItem &&
      showCustomization &&
      item &&
      dbId &&
      order.id != dbId &&
      editCartItem?.id == dbId
    ) {
      dispatchItemCustomization(addTry2ComboItem(item));
      setFormState({
        ...formState,
        quantity: editCartItem?.quantity,
        name:
          editCartItem?.recipient_name !== null
            ? editCartItem?.recipient_name.split(',')
            : '',
        instructions:
          editCartItem?.special_instructions !== null
            ? editCartItem?.special_instructions
            : '',
      });
      // dispatchItemCustomization(
      //   addTry2ComboItemBasePrice({ price: tem.price }),
      // );

      if (editCartItem.is_choose_for_me && !editItemFetching) {
        dispatchItemCustomization(
          addAddChoseForMeSize({
            size: editCartItem.item_size,
            item_id: dbId,
          }),
        );
        setChooseForMe(true);
      }
      handleInitialSync();
    }
    // [item, editCartItem, dbId];
  }, [item, editCartItem, dbId, editItemFetching]);

  const shouldRender = () => {
    return (
      order.id != item_id &&
      !isFetching &&
      item &&
      !dbId &&
      !showCustomization &&
      order?.makeAnItemTry2Combo?.bucket
    );
  };

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth' });
    if (!isFetching && !order?.makeAnItemTry2Combo?.bucket && !dbId) {
      if (item?.is_single_item) {
        itemBuckets.resetBuckets();
        setBoxLunchCombo(CONSTANT.SINGLEITEM_BOXLUNCH);
      }
      dispatchItemCustomization(addTry2ComboItem(item));
      itemBuckets.createComboBucket({
        keys: [
          CONSTANT.REQUIRED_MODIFIERS,
          CONSTANT.CORE_MODIFIERS,
          CONSTANT.ADD_ONS,
          CONSTANT.COMPLIMENTARY_MODIFIER,
        ],
        items: item?.is_single_item ? CONSTANT.SINGLE_ITEM_SELECTED_SECTION : 2,
        handleItemOwnModifiers: true,
      });
      item && handleInitialSync();
      return () => {
        itemBuckets.resetBuckets();

        dispatch(resetContext());
      };
    }
  }, [isFetching]);
  const handleTry2ComboComplimentaryModifiers = async (item: any) => {
    const complimentaryModifiers = item.complementary_items ?? [];
    complimentaryModifiers.forEach((modifier) => {
      modifier.modifiers_groups_modifiers?.forEach((selectedItem) => {
        if (!selectedItem.is_selected) return;
        const payload = createPayloadToSync(
          {
            ...selectedItem,
            modifier_type: CONSTANT.COMPLIMENTARY_MODIFIER,
          },
          item,
        );
        calculatePriceForItem({
          currentModifier: payload,
          item_id: item?.is_single_item
            ? 2
            : CONSTANT.COMPLEMENTARY_SELECTED_SECTION_FOR_TRY_TO_COMBO_ITEM,
        });
      });
    });
    return true;
  };

  const handleZeroCore = async (
    zeroPriceCore: {
      customizedIngredient: any;
      data: any;
      iterations: number;
      quantity: number;
      itemID: any;
    }[],
  ) => {
    zeroPriceCore.forEach((element) => {
      const {
        customizedIngredient,
        data,
        iterations,
        quantity,
        itemID: id,
      } = element;
      const item_id: any = parseInt(id ?? selectedItem);
      for (let j = 0; j < iterations; j++) {
        let tempQuantity = 0;
        if (customizedIngredient) data.code = customizedIngredient.code;
        if (data.code === CONSTANT.ADD) {
          tempQuantity = j + 1;
          data.type = j === 0 ? CONSTANT.BY_DEFAULT : CONSTANT.INCREASE;
          data.code = CONSTANT.ADD;
        } else if (data.code === CONSTANT.NO) {
          data.type = CONSTANT.DECREASE;
          tempQuantity = 0;
        } else {
          tempQuantity = quantity;
        }
        calculatePriceForItem({
          currentModifier: { ...data, quantity: tempQuantity },
          item_id,
        });
        if (data.code === CONSTANT.NO) break;
      }
    });
  };

  const handleInitialSync = async (IsNotEdit = false) => {
    // if (showCustomization && !editCartItem) return;

    const zeroPriceCore = [];
    if (item) await handleTry2ComboComplimentaryModifiers(item);
    if (showCustomization && editCartItem && !IsNotEdit) {
      // dispatchItemCustomization(addTry2ComboItem(item));
      const sectionHandler = item?.is_single_item
        ? CONSTANT.SINGLE_ITEM_SELECTED_SECTION
        : CONSTANT.BOX_LUNCH_SELECTED_SECTION;
      const EditSingle = item?.is_single_item
        ? CONSTANT.SINGLE_ITEM
        : CONSTANT.TRY_TO_COMBO_ITEM;
      const itemId = item?.is_single_item ? 2 : 3;
      await processEditItemModifiers(editCartItem, item, EditSingle, itemId);
      if (!editCartItem.is_choose_for_me) {
        for (let i = 0; i < sectionHandler; i++) {
          const cartItem = editCartItem.modifiers[i];
          if (!cartItem?.is_item) {
            return;
          }
          const categoryId = cartItem.category_id;
          const section = (i + 1).toString();
          const category = item.sub_categories.find(
            (category) => category.id === categoryId,
          );
          const INitem = category.items.find(
            (categoryItem) => categoryItem.id === cartItem.modifier_id,
          );

          itemBuckets?.addCoreModifierMemoryChip(
            i + 1,
            INitem?.bucket,
            INitem?.items_modifier_groups,
          );
          await handleSyncIngredients(INitem, i + 1, cartItem, zeroPriceCore);
          await processEditItemModifiers(
            cartItem,
            INitem,
            CONSTANT.TRY_TO_COMBO_ITEM,
            i + 1,
          );
          await handleZeroCore(zeroPriceCore);
          if (cartItem.no_required_modifier)
            await handleNoDressing(cartItem.no_required_modifier, section);
        }
      }

      const addedCombboCount = item?.is_single_item
        ? [CONSTANT.COMBO_ITEM_ONE]
        : [CONSTANT.COMBO_ITEM_ONE, CONSTANT.COMBO_ITEM_TWO];
      // setAddedItemInTry2Combo(addedCombboCount);
      setItemDetails(true);
    }
    await updateModifierStates();
    if (
      showCustomization &&
      editCartItem &&
      !IsNotEdit &&
      !editCartItem?.is_choose_for_me
    ) {
      await editItemToTrytoCombo();
    }
  };
  const handleNoDressing = (modifiers: any, itemNo) => {
    for (let i = 0; i < modifiers.length; i++) {
      itemBuckets.resetChangesOnNoDreesing(itemNo, {
        ...modifiers[i],
        quantity: 1,
      });
    }
  };
  const editItemToTrytoCombo = async () => {
    const itemCount = item?.is_single_item
      ? CONSTANT.SINGLE_ITEM_SELECTED_SECTION
      : CONSTANT.BOX_LUNCH_SELECTED_SECTION;
    for (let i = 0; i < itemCount; i++) {
      const cartItem = editCartItem.modifiers[i];
      const size = editCartItem.item_size;
      if (!cartItem.is_item) return;
      const categoryId = cartItem.category_id;
      const section = (i + 1).toString();
      const category = item?.sub_categories.find(
        (category) => category.id === categoryId,
      );
      const INitem = category.items.find(
        (categoryItem) => categoryItem.id === cartItem.modifier_id,
      );
      dispatchItemCustomization(
        editComboItems({
          id: editCartItem.id,
          sectionSelected: section,
          item: INitem,
          category: category,
          itemName: CONSTANT.TRY_2_COMBO,
          size,
        }),
      );
    }
  };

  const handleAddToOrder = async (structuredOrder) => {
    const category = Number(category_id);
    // eslint-disable-next-line prefer-const
    let finalOrderObject: any = finalOrder({
      order,
      category_id: category,
      noOfItems: item?.is_single_item ? 1 : 2,
      comboType: CONSTANT.COMBO_TYPE_IS_TRY_2_COMBO,
      items: item,
      totalSelectedSection: CONSTANT.BOX_LUNCH_SELECTED_SECTION,
      is_box_lunch: true,
    });
    finalOrderObject.recipient_name = formState.name.toString();
    if (formState.quantity) finalOrderObject.quantity = formState.quantity;
    finalOrderObject['special_instructions'] = formState.instructions;
    finalOrderObject['is_choose_for_me'] = chooseForMe;

    //  ;
    if (item.is_full_size && item?.is_single_item) {
      // finalOrderObject.item_size = 'FULL';
      finalOrderObject.modifiers.forEach((data, index) => {
        if (data.size) {
          finalOrderObject.modifiers[index].size = 'FULL';
        }
      });
    }
    // if (item?.is_single_item) {
    //   if (finalOrderObject.modifiers[1].modifiers.length < 1) {
    //     finalOrderObject.modifiers.pop();
    //   }
    // }
    const reqMod = finalOrderObject.modifiers.findIndex(
      (item) => item.item === 'REQUIRED_ITEM',
    );
    if (reqMod !== undefined) {
      const modifiers = cloneDeep(finalOrderObject.modifiers[reqMod].modifiers);
      finalOrderObject.modifiers.splice(reqMod, 1);
      for (const modifier of modifiers) {
        finalOrderObject.modifiers.push(modifier);
      }
    }
    if (chooseForMe) {
      if (item?.is_single_item) {
        finalOrderObject.modifiers.shift();
      } else {
        finalOrderObject.modifiers.shift();
        finalOrderObject.modifiers.shift();
      }

      // finalOrderObject.modifiers = [];
    } else {
      if (
        finalOrderObject.modifiers[finalOrderObject.modifiers.length - 1] ===
        undefined
      ) {
        finalOrderObject.modifiers.pop();
      }
    }

    const response = await addToOrder(
      finalOrderObject,
      true,
      formState.quantity,
    );
    if (response) {
      if (!showCustomization) {
        setSuccessModalShow(true);
      }

      // window.scrollTo(0, 0);
      // resetTry2Combo();

      setFormState({
        quantity: 1,
        name: [],
        instructions: '',
        reset: true,
      });
    }
    return response;
  };
  const updateModifierStates = async () => {
    const modifierPrices = [];
    const itemBucket = itemBuckets.getBuckets();
    for (let i = 0; i < itemBucket?.length; i++) {
      const currentItemBucket = itemBucket[i];
      const bucketTypes = Object.keys(currentItemBucket?.bucket);
      for (let i = 0; i < bucketTypes.length; i++) {
        const type = bucketTypes[i];
        const activeBucket = currentItemBucket?.bucket[type];
        const itemId =
          currentItemBucket?.item === 3
            ? CONSTANT.COMPLEMENTARY_ITEM
            : currentItemBucket?.item;
        modifierPrices.push({ activeBucket, itemId });
      }
    }
    const firstItem = modifiersAgainstAnItem({
      itemId: CONSTANT.BOX_LUNCH_SELECTED_SECTION_FIRST_SECTION,
    });
    let secondItem;
    if (!item?.is_single_item) {
      secondItem = modifiersAgainstAnItem({
        itemId: CONSTANT.BOX_LUNCH_SELECTED_SECTION,
      });
    }

    const complementaryAndRequiredmodifiers = modifiersAgainstAnItem({
      itemId: item?.is_single_item
        ? 2
        : CONSTANT.COMPLEMENTARY_SELECTED_SECTION_FOR_TRY_TO_COMBO_ITEM,
    });
    const result = complementaryAndRequiredmodifiers.reduce(
      (acc, modifier) => {
        if (modifier.modifier_type === CONSTANT.COMPLIMENTARY_MODIFIER) {
          acc.complementary.push(modifier);
        } else if (modifier.modifier_type === CONSTANT.REQUIRED_MODIFIERS) {
          acc.required.push(modifier);
        }
        return acc;
      },
      { complementary: [], required: [] },
    );
    const modifiers = [];
    modifiers.push({ itemModifiers: firstItem, itemId: 1 });
    if (!item?.is_single_item) {
      modifiers.push({ itemModifiers: secondItem, itemId: 2 });
    }
    modifiers.push({
      itemModifiers: result.complementary,
      itemId: CONSTANT.COMPLEMENTARY_ITEM,
    });
    modifiers.push({
      itemModifiers: result.required,
      itemId: CONSTANT.REQUIRED_ITEM,
    });
    dispatchItemCustomization(updateModifiers(modifiers));
    dispatchItemCustomization(updatePriceForAllItem(modifierPrices));
  };

  const handleIngredientSelection: (
    data: any | ISelectedModifier,
    id?: any,
  ) => void = (data, id = sectionSelected) => {
    const { itemId, activeBucket, itemModifiers } = calculatePriceForItem({
      currentModifier: data,
      item_id: id,
    });

    //item id should be the one which item is selected
    dispatchItemCustomization(
      updateSpecificItemSelectedModifiers({ itemModifiers, itemId }),
    );
    if (activeBucket !== undefined) {
      dispatchItemCustomization(updatePriceForItem({ itemId, activeBucket }));
    }
  };

  const handleComplementaryIngredientSelection: (
    data: any,
    id?: 1 | 2 | 3 | 4,
  ) => void = (
    data,
    id = item?.is_single_item
      ? 2
      : CONSTANT.COMPLEMENTARY_SELECTED_SECTION_FOR_TRY_TO_COMBO_ITEM,
  ) => {
    const { itemId, activeBucket, itemModifiers } = calculatePriceForItem({
      currentModifier: data,
      item_id: id,
    });
    const filteredMod = itemModifiers.filter(
      (item) => item.modifier_type === CONSTANT.COMPLIMENTARY_MODIFIER,
    );

    dispatchItemCustomization(
      updateSpecificItemSelectedModifiers({
        itemModifiers: filteredMod,
        itemId,
      }),
    );
    if (activeBucket !== undefined) {
      dispatchItemCustomization(updatePriceForItem({ itemId, activeBucket }));
    }
  };
  const handleRequiredIngredientSelection: (
    data: any,
    id?: 1 | 2 | 3 | 4,
  ) => void = (
    data,
    id = item?.is_single_item
      ? 2
      : CONSTANT.COMPLEMENTARY_SELECTED_SECTION_FOR_TRY_TO_COMBO_ITEM,
  ) => {
    const { itemId, activeBucket, itemModifiers } = calculatePriceForItem({
      currentModifier: data,
      item_id: id,
    });
    const filteredMod = itemModifiers.filter(
      (item) => item.modifier_type === 'required_modifiers',
    );

    dispatchItemCustomization(
      updateSpecificItemSelectedModifiers({
        itemModifiers: filteredMod,
        itemId: CONSTANT.REQUIRED_ITEM,
      }),
    );
    if (activeBucket !== undefined) {
      dispatchItemCustomization(updatePriceForItem({ itemId, activeBucket }));
    }
  };

  /**
   * We'll use this hook after
   * @method {componentDidMount} and {componentWillUnMount}
   * @requires [This order must be followed]
   */
  // useHandleByDefaultModifierSelections(item, showCustomization);
  /**
   * End
   */

  //Box Lunches Implementation

  const selectedIngredients = (orderItem) => {
    return orderItem.modifiers;
  };
  const showCustomizationModalHandler = () => {
    itemBuckets.createBucketCopy();
    setRequiredModalShow(true);
  };

  const updateSingleBucket = (itemNo: 1 | 2 | 3 | 4) => {
    const priceArray = [];
    let updatedBucketId: any = itemNo;
    const itemBucket = itemBuckets.getSingleItem({ itemNo });
    const bucketTypes = Object.keys(itemBucket?.bucket);
    bucketTypes.forEach((type) => {
      const activeBucket = itemBucket?.bucket[type];
      priceArray.push({ activeBucket, itemId: itemNo, isCustomize: true });
    });
    const itemModifiers = modifiersAgainstAnItem({
      itemId: itemNo,
    });
    const modifiers = [];
    if (item?.is_single_item) {
      if (
        itemNo ===
        CONSTANT.COMPLEMENTARY_SELECTED_SECTION_FOR_TRY_TO_COMBO_ITEM - 1
      ) {
        updatedBucketId = CONSTANT.COMPLEMENTARY_ITEM;
      }
      if (itemNo === 3) {
        updatedBucketId = CONSTANT.REQUIRED_ITEM;
      }
    } else {
      if (
        itemNo === CONSTANT.COMPLEMENTARY_SELECTED_SECTION_FOR_TRY_TO_COMBO_ITEM
      ) {
        updatedBucketId = CONSTANT.COMPLEMENTARY_ITEM;
      }
      if (itemNo === 4) {
        updatedBucketId = CONSTANT.REQUIRED_ITEM;
      }
    }
    modifiers.push({ itemModifiers, itemId: updatedBucketId });
    dispatchItemCustomization(updateModifiers(modifiers));
    dispatchItemCustomization(updatePriceForAllItem(priceArray));
  };
  const handleSelectedModifiers = async (
    item: any,
    bucketId: 1 | 2 | 3 | 4 = 1,
  ) => {
    const selectedModifiers = item.is_selected;
    selectedModifiers.forEach((selectedItem) => {
      const payload = createPayloadToSync(
        { ...selectedItem, modifier_type: CONSTANT.CORE_MODIFIERS },
        item,
      );
      calculatePriceForItem({ currentModifier: payload, item_id: bucketId });
    });
    return true;
  };
  const handleSync86Ingredients = async (item, bucketId) => {
    const ingredients = unavailableModifiers(item);
    for (let i = 0; i < ingredients.length; i++) {
      const data: any = {
        modifier_id: ingredients[i].id,
        modifier_name: ingredients[i].name,
        modifier_group_id: ingredients[i].modifier_group_id,
        modifier_group_min: ingredients[i].modifier_group_min,
        modifier_calories: ingredients[i].calories,
        brink_id: ingredients[i].brink_modifier_id,
        quantity: 0,
        brink_modifier_group_id: ingredients[i].brink_modifier_group_id,
        display_price: ingredients[i].price,
        type: CONSTANT.DECREASE,
        modifier_type: CONSTANT.CORE_MODIFIERS,
        image: `${CONSTANT.REACT_APP_CLOUD_FRONT}/Catering/Modifiers/${ingredients[i].id}/modifier-${ingredients[i].id}.webp`,
      };
      const itemId: 1 | 2 | 3 = parseInt(bucketId) as 1 | 2 | 3;
      calculatePriceForItem({ currentModifier: data, item_id: itemId });
      return true;
    }
  };
  const handleSyncIngredients = async (
    item,
    id = null,
    cartItem = null,
    zeroPriceCore = [],
  ) => {
    const itemID = id ? id : parseInt(selectedItemCount);
    let ingredients = _clonedeep(availableModifiers(item));
    if (ingredients.length) {
      ingredients = sortBasedOnPriceByModifierGroups(ingredients);
    }
    for (let i = 0; i < ingredients.length; i++) {
      let customizedIngredient = null;
      let iterations = 1;
      const quantity = parseInt(ingredients[i].quantity) || 1;
      const data: any = {
        modifier_id: ingredients[i].id,
        modifier_name: ingredients[i].name,
        modifier_group_id: ingredients[i].modifier_group_id,
        modifier_calories: ingredients[i].calories,
        brink_id: ingredients[i].brink_modifier_id,
        quantity: quantity,
        brink_modifier_group_id: ingredients[i].brink_modifier_group_id,
        display_price: ingredients[i].price,
        core: true,
        type: CONSTANT.BY_DEFAULT,
        modifier_type: CONSTANT.CORE_MODIFIERS,
        image: `${CONSTANT.REACT_APP_CLOUD_FRONT}/Catering/Modifiers/${ingredients[i].id}/modifier-${ingredients[i].id}.webp`,
      };
      //In case of Edit update core modifier selection
      if (showCustomization && cartItem?.core_modifiers) {
        let customizedIngredients = _clonedeep(cartItem.core_modifiers ?? []);
        if (customizedIngredients.length) {
          customizedIngredients = sortBasedOnPriceByModifierGroups(
            customizedIngredients,
          );
        }
        customizedIngredient = customizedIngredients.find(
          (mod) => mod.modifier_id === ingredients[i].id,
        );
      }
      iterations =
        customizedIngredient?.quantity > 1 ? customizedIngredient?.quantity : 1;
      // save zero price core to add them at the end
      if (
        customizedIngredient?.price === 0 &&
        showCustomization &&
        editCartItem?.core_modifiers
      ) {
        zeroPriceCore.push({
          customizedIngredient,
          data,
          iterations,
          quantity,
          itemID,
        });
        continue;
      }
      for (let j = 0; j < iterations; j++) {
        let tempQuantity = 0;
        if (customizedIngredient) data.code = customizedIngredient.code;
        if (data.code === CONSTANT.ADD) {
          tempQuantity = j + 1;
          data.type = j === 0 ? CONSTANT.BY_DEFAULT : CONSTANT.INCREASE;
          data.code = CONSTANT.ADD;
        } else if (data.code === CONSTANT.NO) {
          data.type = CONSTANT.DECREASE;
          tempQuantity = 0;
        } else {
          tempQuantity = quantity;
        }
        calculatePriceForItem({
          currentModifier: { ...data, quantity: tempQuantity },
          item_id: id ?? selectedItemCount,
        });
        if (data.code === CONSTANT.NO) break;
      }
    }
    await handleSelectedModifiers(item, itemID);
    await handleSync86Ingredients(item, itemID);
    if (!id) updateSingleBucket(itemID);
  };
  const scrollDescritionToCenter = (value: number) => {
    autoScrollDivRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
    const elementRect = autoScrollDivRef?.current?.getBoundingClientRect();
    if (elementRect) {
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle = absoluteElementTop - window.innerHeight / value;
      window.scrollTo(0, middle);
    }
  };
  const handleScroll = () => {
    const isBothItemSelected = checkIfUserAddSecondItemAfterOne(order);
    if (isBothItemSelected && isThisMobile) {
      scrollDescritionToCenter(15);
    }
    // else document.documentElement.scrollTop = 0;  //? uncomment this and adjust scroll if you have to add when second item selection
  };
  const decideCardSelection = (
    items = addedItemInTry2Combo,
    selected = sectionSelected,
  ) => {
    if (
      selected === CONSTANT.COMBO_ITEM_ONE &&
      items.includes(CONSTANT.COMBO_ITEM_TWO)
    ) {
      setSelectedItemCount('');
    } else if (
      selected === CONSTANT.COMBO_ITEM_TWO &&
      items.includes(CONSTANT.COMBO_ITEM_ONE)
    ) {
      setSelectedItemCount('');
    } else if (selected === CONSTANT.COMBO_ITEM_ONE) {
      setSelectedItemCount(CONSTANT.COMBO_ITEM_TWO);
    } else {
      setSelectedItemCount(CONSTANT.COMBO_ITEM_ONE);
    }
  };
  const addItemtoCombo = (paramItem: ItemDetails) => {
    const itemDetails: ISelectedItemDetailsForModal[] =
      try2ComboItemDetails?.map((currentItem) => {
        if (currentItem.id === selectedItemCount) {
          currentItem.is_selected = true;
          currentItem.itemName = paramItem?.name;
          currentItem.image = paramItem?.image;
        }
        return currentItem;
      });
    setTry2ComboItemDetails(itemDetails);
    scrollDescritionToCenter(400);
    setAddedItemInTry2Combo([...addedItemInTry2Combo, selectedItemCount]);
    // if (!item?.is_single_item) {
    decideCardSelection();
    // }
    handleScroll();
    dispatchItemCustomization(
      try2ComboItemSelection({
        sectionSelected,
        item: paramItem,
        itemName: CONSTANT.TRY_2_COMBO,
      }),
    );
    itemBuckets.resetCopyComboBucket();
    if (isEitherItemSelect()) {
      togglePersonalize();
    } else if (item?.is_single_item) {
      setChooseForMe(false);
      setPersonalize(!personalize);
    }
  };
  const noDressingHandler = (modifier: INoDressingModifier, itemNo) => {
    dispatchItemCustomization(
      removeTemporarilyAddedPriceForItem({
        price: itemBuckets.resetChangesOnNoDreesing(itemNo, modifier),
        item: itemNo,
      }),
    );
    dispatchItemCustomization(
      updateSpecificItemSelectedModifiers({
        itemModifiers: modifiersAgainstAnItem({
          itemId: CONSTANT.BOX_LUNCH_SELECTED_SECTION_FIRST_SECTION,
        }),
        itemId: CONSTANT.BOX_LUNCH_SELECTED_SECTION_FIRST_SECTION.toString(),
      }),
    );
  };

  const customizeModal = (item: ItemDetails) => {
    EventBusForBYOCategoryScrollingBetweenAccordions.unsubscribe(
      'checkForMissingRequiredModifers',
      checkForMisssingRequiredModifierGroups,
    );
    itemBuckets.addCoreModifierMemoryChip(
      sectionSelected,
      item.bucket,
      item?.items_modifier_groups,
    );
    emptySelectedItemSelectedModifiers();
    if (isItCreateYourOwnItem(item?.is_create_your_own)) {
      setSelectedItem(item);
      showIngredientsModalHandler(item);
      subscribeEvent.current = true;
    } else if (item?.items_required_modifier_groups.length > 0) {
      setIsShowingRequiredModalOnCustomization(true);
      setSelectedItem(item);
      showCustomizationModalHandler();
    } else {
      setSelectedItem(item);
      showIngredientsModalHandler(item);
    }
  };

  const selectedOrder = () => {
    let result = [];
    order.modifiers.map((itemModifier) => {
      if (itemModifier.item === sectionSelected) {
        result = itemModifier;
      }
    });
    return result;
  };
  const emptySelectedItemSelectedModifiers = () => {
    //Filter slected item modifiers and consider selectedIngredients as a temporary selection
    dispatch(
      removeTry2ComboIngredients({
        modifiers: selectedIngredients(selectedOrder()),
        sectionSelected: sectionSelected,
      }),
    );
  };
  const showIngredientsModalHandler = (item?: ItemDetails) => {
    itemBuckets.createBucketCopy();
    handleSyncIngredients(item);
    setIngredientsModal(true);
  };

  const changeSelection = (section) => {
    setSectionSelected(section);
  };
  const findSelectedItemAvailability = (find_id) => {
    const items = order.modifiers.filter((obj) => {
      return obj.is_selected;
    });
    return items.find((item) => item.item === find_id);
  };
  const Item = (selected: ISelectedItem, id: string) => {
    return (
      selected &&
      id === selected.item &&
      item.sub_categories
        .map((category) => category.items)
        .flat()
        .find((item) => item.id == selected.id)
    );
  };
  const Category = (selected: ISelectedItem, id: string) => {
    return (
      selected &&
      id === selected.item &&
      item.sub_categories.flat().find((item) => {
        return item.id == selected.category_id;
      })
    );
  };

  const clickOnItemsAfterCategorySelection = (onRemove: boolean) => {
    const addedInCombo = [];
    for (let i = 0; i < order?.modifiers.length - 1; i++) {
      if (order?.modifiers[i].category_id !== null) {
        if (order?.modifiers[i].id !== null) {
          addedInCombo.push(true);
        } else {
          addedInCombo.push(false);
        }
      }
    }
    const checkCondition = onRemove
      ? addedInCombo.includes(false)
      : addedInCombo.includes(false) && !addedInCombo.includes(true);
    if (checkCondition) {
      return true;
    } else {
      return false;
    }
  };
  const removeSingleCategory = (id) => {
    let addedItems = [...addedItemInTry2Combo];
    const index = addedItemInTry2Combo.findIndex((item) => item === id);
    if (index > -1) {
      setAddedItemInTry2Combo(
        addedItemInTry2Combo.filter((item) => item !== id),
      );
      addedItems = addedItems.filter((item) => item !== id);
    }
    decideCardSelection(
      addedItems,
      id === CONSTANT.COMBO_ITEM_ONE
        ? CONSTANT.COMBO_ITEM_TWO
        : CONSTANT.COMBO_ITEM_ONE,
    );
  };
  const onItemSelectionChange = (id: string, scroll = true) => {
    if (scroll && itemDetails) {
      isThisMobile && scrollDescritionToCenter(4.2);
    }
    if (clickOnItemsAfterCategorySelection(false) && !item?.is_single_item) {
      dispatchItemCustomization(swapTry2ComboContext());
    }

    if (!item?.is_single_item) {
      setSectionSelected(id);
      setSelectedItemCount(id);
    }
  };
  const addToComboClickHandler = (item: ItemDetails) => {
    itemBuckets.addCoreModifierMemoryChip(
      sectionSelected,
      item.bucket,
      item?.items_modifier_groups,
    );
    EventBusForBYOCategoryScrollingBetweenAccordions.unsubscribe(
      'checkForMissingRequiredModifers',
      checkForMisssingRequiredModifierGroups,
    );
    if (isItCreateYourOwnItem(item?.is_create_your_own)) {
      setIsCreateYourOwnItem(true);
      setSelectedItem(item);
      showIngredientsModalHandler(item);
      subscribeEvent.current = true;
    } else if (item?.items_required_modifier_groups.length > 0) {
      // itemBuckets.createBucketCopy();
      emptySelectedItemSelectedModifiers();
      setIsShowingRequiredModalOnCustomization(false);
      setSelectedItem(item);
      showCustomizationModalHandler();
    } else {
      directAddItemToCombo(item);
      setIsCreateYourOwnItem(false);
    }
  };
  const directAddItemToCombo = (item) => {
    handleSyncIngredients(item);
    // addSelectedIngredients(item)
    // backButtonClick();
    addItemtoCombo(item);
  };

  const onRemoveClickHandler = (id) => {
    //  ;
    const itemDetails: ISelectedItemDetailsForModal[] =
      try2ComboItemDetails?.map((currentItem) => {
        if (currentItem.id === id) {
          currentItem.is_selected = false;

          currentItem.itemName = null;
          currentItem.image = null;
        }
        return currentItem;
      });
    setTry2ComboItemDetails(itemDetails);
    setSelectedItem(null);
    EventBusForBYOCategoryScrollingBetweenAccordions.unsubscribe(
      'checkForMissingRequiredModifers',
      checkForMisssingRequiredModifierGroups,
    );
    if (clickOnItemsAfterCategorySelection(true)) {
      removeSingleCategory(id);
      dispatchItemCustomization(resetTry2ComboContext());
      setItemDetails(true);
      setSelectedItemCount(CONSTANT.COMBO_ITEM_ONE);
    } else {
      if (item?.is_single_item) {
        setCallRadioChange(true);
      }
      removeSingleCategory(id);
      dispatchItemCustomization(removeSpecificItem({ id }));
    }
  };
  const isEitherItemSelect = () => {
    let show = false;
    if (order?.name) {
      for (let i = 0; i < order?.modifiers.length - 1; i++) {
        if (order?.modifiers[i]?.is_selected) {
          show = true;
        }
      }
      return show;
    } else {
      return false;
    }
  };
  const isBothItemsSelected = () => {
    let show = true;
    if (order?.name) {
      for (let i = 0; i < order?.modifiers.length - 1; i++) {
        if (!order?.modifiers[i]?.is_selected) {
          show = false;
        }
      }
      return show;
    } else {
      return false;
    }
  };

  const flushMemory = async (resetPrice?: boolean) => {
    dispatchItemCustomization(
      resetChooseForMe({ is_single_item: item?.is_single_item }),
    );
    itemBuckets.resetSpecificComboBucket(1);
    if (!item?.is_single_item) {
      itemBuckets.resetSpecificComboBucket(2);
    }
    await updateModifierStates();
    setSectionSelected(CONSTANT.COMBO_ITEM_ONE);
    setSelectedItem('');

    setSelectedItemCount(CONSTANT.COMBO_ITEM_ONE);
  };
  const showIngredientsModalHandlerBoxLunch = () => {
    itemBuckets.createBucketCopy();
    setEditIngredientModal(true);
  };
  const onEditClickHandler = (name: string, id: string) => {
    setLoadValueForModal({ load: true, name, id });
    showIngredientsModalHandlerBoxLunch();
  };

  const isBucketCreated = () => {
    if (item?.is_single_item) {
      return itemBuckets.getBuckets()?.length < 2;
    }
    if (item?.is_package) {
      return itemBuckets.getBuckets()?.length < 3;
    }
    return itemBuckets.getBuckets()?.length < 2;
  };

  return isFetching || isBucketCreated() || editItemFetching ? (
    <Loader />
  ) : (
    <>
      {/* {personalize || editfromDetails ? ( */}
      <BoxLunches
        subCategories={item?.sub_categories}
        togglePersonalize={togglePersonalize}
        sectionSelected={sectionSelected}
        addItemtoCombo={addItemtoCombo}
        handleSyncIngredients={(item) => handleSyncIngredients(item)}
        noDressingHandler={noDressingHandler}
        emptySelectedItemSelectedModifiers={emptySelectedItemSelectedModifiers}
        findSelectedItemAvailability={findSelectedItemAvailability}
        boxLunchCombo={boxLunchCombo}
        handleClickCustomize={customizeModal}
        hideCustomizationModalHandler={hideCustomizationModalHandler}
        requiredModalShow={requiredModalShow}
        selectedItem={selectedItem}
        ingredientsModal={ingredientsModal}
        toggleIngrediatsModal={toggleIngrediatsModal}
        handleIngredientSelection={handleIngredientSelection}
        isShowingRequiredModalOnCustomization={
          isShowingRequiredModalOnCustomization
        }
        changeSelection={changeSelection}
        Item={Item}
        Category={Category}
        itemDetails={itemDetails}
        setSectionSelected={(id, scroll) => onItemSelectionChange(id, scroll)}
        selectedItemCount={selectedItemCount}
        onRemoveClickHandler={onRemoveClickHandler}
        apiItem={item}
        showCustomization={showCustomization}
        setSelectedItem={setSelectedItem}
        addToComboClickHandler={addToComboClickHandler}
        DescriptionScrollRef={autoScrollDivRef}
        flushMemory={flushMemory}
        isBothItemsSelected={isBothItemsSelected}
        isEdit={editCartItem ? true : false}
        callRadioChange={callRadioChange}
        setCallRadioChange={setCallRadioChange}
        loadValueForModal={loadValueForModal}
        setLoadValueForModal={setLoadValueForModal}
        editIngredientModal={editIngredientModal}
        setEditIngredientModal={setEditIngredientModal}
        showIngredientsModalHandler={showIngredientsModalHandlerBoxLunch}
        onEditClickHandler={onEditClickHandler}
        personalize={personalize}
        editfromDetails={editfromDetails}
        isCreateYourOwnItem={isCreateYourOwnItem}
      />
      {!personalize && item && (
        <div className={`${styles.item_detail_wraper}`}>
          <Container fluid>
            <ItemDetail
              item={item}
              state={state}
              togglePersonalize={togglePersonalize}
              handleIngredientSelection={handleRequiredIngredientSelection}
              handleComplementaryIngredientSelection={
                handleComplementaryIngredientSelection
              }
              isBoxLunch={true}
              activeKey={item?.category_name}
              showModifierModal={function (): void {
                throw new Error('Function not implemented.');
              }}
              addToOrder={handleAddToOrder}
              boxLunchCombo={boxLunchCombo}
              findSelectedItemAvailability={findSelectedItemAvailability}
              Item={Item}
              Category={Category}
              loading={loading}
              formState={formState}
              setFormState={setFormState}
              resetBoxLunch={flushMemory}
              showCustomization={showCustomization}
              dbId={dbId}
              setChoseForMe={setChooseForMe}
              onRemoveClickHandler={(id) => {
                setPersonalize(true);
                onRemoveClickHandler(id);
              }}
              onEditClickHandler={onEditClickHandler}
              setEditFromDetails={setEditFromDetails}
              editCartItem={editCartItem}
              requiredModifiersAtItemDetailSectionToHandleScroll={
                requiredModifiersAtItemDetailSectionToHandleScroll
              }
              isCreateYourOwnItem={isItCreateYourOwnItem(
                item?.is_create_your_own,
              )}
              isSelectedItemCreateYourOwn={isCreateYourOwnItem}
            />
          </Container>
        </div>
      )}
      {/* )} */}
      {successModalShow && (
        <AddedModal
          showModal={successModalShow}
          closeModal={() => {
            setSuccessModalShow(false);
            setTry2ComboItemDetails([
              { id: '1', itemName: null, image: null, is_selected: false },
              { id: '2', itemName: null, image: null, is_selected: false },
            ]);
          }}
          name={item?.name}
          image={`${CONSTANT.s3BaseUrl}${item?.image}`}
          comboItems={chooseForMe ? undefined : try2ComboItemDetails}
          isSingleItem={item?.is_single_item}
          styleTitle="f-s26 f-sm-s22"
          styleName="text-uppercase f-s18 font-Visby-cf"
          isCombo={true}
          item={item?.category_name}
          itemFromApi={item}
        />
      )}
    </>
  );
};

const useScrollToMissingRequiredArea = (item) => {
  const [
    requiredModifiersRefsForScrollToMissingSelectedGroupInItemDetailSection,
    setRequiredModifiersRefsForScrollToMissingSelectedGroupInItemDetailSection,
  ] = useState([]);

  const [
    isRequiredModifiersAtItemDetailSectionSelected,
    setIsRequiredModifiersAtItemDetailSectionSelected,
  ] = useState({
    status: false,
    highLightArea: null,
  });

  useEffect(() => {
    if (item && item?.items_required_modifier_groups.length > 0) {
      assignRefForScroll({
        requiredSectionLength: item?.items_required_modifier_groups.length,
        refState:
          requiredModifiersRefsForScrollToMissingSelectedGroupInItemDetailSection,
        setRefState:
          setRequiredModifiersRefsForScrollToMissingSelectedGroupInItemDetailSection,
      });
    }
  }, [item]);

  function assignRefForScroll({
    requiredSectionLength,
    refState,
    setRefState,
  }) {
    const divRefsNew = refState;
    divRefsNew.length = requiredSectionLength;
    // Initialize the refs
    for (let i = 0; i < divRefsNew.length; i++) {
      divRefsNew[i] = divRefsNew[i] || React.createRef();
    }
    setRefState(divRefsNew);
  }

  return {
    requiredModifiersAtItemDetailSectionToHandleScroll: {
      refToScrollToMissingRequiredModifiers:
        requiredModifiersRefsForScrollToMissingSelectedGroupInItemDetailSection,
      isRequiredModifierSelected:
        isRequiredModifiersAtItemDetailSectionSelected,
      setIsRequiredModifierSelected:
        setIsRequiredModifiersAtItemDetailSectionSelected,
    },
  };
};
export default BoxLunchesContainer;
