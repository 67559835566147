import React from 'react';
import * as CONSTANT from '../../../constants';
interface IItemModifiersProps {
  modifiers: any[];
  paragraphClassName?: string;
  showMore: boolean;
  isPackage: boolean;
}

const ComboItemModifiers: React.FC<IItemModifiersProps> = ({
  modifiers,
  paragraphClassName,
  showMore,
  isPackage,
}) => {
  if (modifiers.length === 0) return null;

  const handleModifierCode = (code: string, modifier: any) => {
    const quantity = modifier.quantity > 1 ? `(${modifier.quantity})` : '';
    switch (code) {
      case CONSTANT.ADD:
        return (
          <div className="d-flex justify-content-between w-100">
            <span className="ms-0 clr-dark-green">
              {`${modifier.is_item ? '' : '+'} ${
                modifier?.modifier_name
              } ${quantity}`}
            </span>
            {modifier?.total_price > 0 && (
              <span className="ms-0  clr-dark-green">
                {' '}
                {`$${modifier?.total_price.toFixed(2)}`}{' '}
              </span>
            )}
          </div>
        );
      case CONSTANT.NO:
        if (modifier.is_item && modifier.quantity <= 0) return;
        return (
          <span className="ms-0 mb-2 clr-dark-green">{`${
            modifier.is_item ? '' : '-'
          } ${modifier?.modifier_name} ${quantity}`}</span>
        );
      case CONSTANT.SUB:
        return (
          <>
            <span className="ms-0 mb-2 clr-dark-green">{`+ ${modifier?.substituted_modifier?.modifier_name}`}</span>
            <span className="ms-0 mb-2 clr-dark-green">
              {`-${modifier?.modifier_name}`}
            </span>
          </>
        );
      default:
        return null;
    }
  };
  const sliceTill = () => {
    if (isPackage) {
      return showMore ? modifiers.length : 0;
    }
    return showMore ? modifiers.length : 1;
  };

  return (
    modifiers.length > 0 && (
      <div className={paragraphClassName ? paragraphClassName : ''}>
        {modifiers.slice(0, sliceTill()).map((modifier: any, index: number) => {
          return (
            // <>
            <div key={index}>{handleModifierCode(modifier.code, modifier)}</div>
            // </>
          );
        })}
      </div>
    )
  );
};

export default ComboItemModifiers;
