import { useEffect, useState } from 'react';
import { EditPencil } from 'src/assets/images/Svgs/svg';
import { getDestination } from 'src/helper/checkout/PlaceOrderHelper';
import { getCurrentDate } from 'src/helper/checkout/timeSlotHelper';
import { formatDate, formatTime } from 'src/helper/utils';
import {
  CURBSIDE,
  DELIVERY_ORDER_TYPE,
  IN_STORE,
  NO_TIME_SLOTS_PRESENT,
  PICK_UP_ORDER_TYPE,
} from '../../../constants';

interface IOrderDetailProps {
  pickUpSlotDetails: any;
  orderType: string;
  OrderDetailHide?: any;
  handleShowOrderDetails?: any;
  location?: any;
  handleShowPickupMethodModal?: (boolean) => void;
  handleShowDeliveryMethodModal?: (boolean) => void;
  orderTypeValidationMessage?: string;
  setOrderTypeValidationMessage?: (string) => void;
  convertedDate?: string;
  pickUpMethod?: string;
  isOrdered?: boolean;
  hideEditButton?: boolean;
}

const OrderDetailsPickupMethod = (props: IOrderDetailProps) => {
  const {
    isOrdered,
    handleShowPickupMethodModal,
    handleShowDeliveryMethodModal,
    pickUpSlotDetails,
    orderType,
    orderTypeValidationMessage,
    convertedDate = '',
    pickUpMethod,
    setOrderTypeValidationMessage,
    hideEditButton,
  } = props;
  const [isConvertedDate, setConvertedDate] = useState(convertedDate);
  const obj = {
    PICK_UP_ORDER_METHOD: 'Pickup Method',
    DELIVERY_ORDER_METHOD: 'Delivery',
  };

  const orderDetails = pickUpSlotDetails;

  useEffect(() => {
    if (!convertedDate && orderDetails?.date) {
      if (orderDetails?.time) setOrderTypeValidationMessage('');
      let time = '';
      time = formatTime(time, orderDetails);
      const date = formatDate(orderDetails.date);
      const dateStr = date + ' @ ' + time;
      setConvertedDate(dateStr);
    } else if (!convertedDate) {
      setConvertedDate(
        formatDate(getCurrentDate()) + '@' + NO_TIME_SLOTS_PRESENT,
      );
    }
  }, [orderDetails]);

  const handleModal = () => {
    if (orderType == PICK_UP_ORDER_TYPE) {
      handleShowPickupMethodModal(true);
    } else {
      handleShowDeliveryMethodModal(true);
    }
  };

  let orderTypeTitle =
    pickUpMethod || getDestination(orderDetails?.pickupMethod)?.order_type;
  if (orderTypeTitle === 'Online Takeout') orderTypeTitle = IN_STORE;
  if ([IN_STORE, CURBSIDE].includes(orderTypeTitle))
    orderTypeTitle += ' Pickup';
  if (orderType === DELIVERY_ORDER_TYPE) orderTypeTitle = 'Delivery';

  return (
    <>
      <hr className="custom_hr_sty d-md-none d-block mt-5" />
      <div className="d-flex justify-content-between align-items-center cz-mt-30 mb-2">
        <h4 className="f-s20 f-sm-16 mb-0 text-start cz-lh-22 text-uppercase font-Cls fw-normal f-sm-s16 pt-2 f-w5">
          {orderType == PICK_UP_ORDER_TYPE
            ? obj.PICK_UP_ORDER_METHOD
            : obj.DELIVERY_ORDER_METHOD}
        </h4>
        <button
          className={`${
            isOrdered || hideEditButton ? 'd-none' : ''
          }  btn f-s16 text_underline clr-text-grey ms-3 p-0 d-md-none`}
          onClick={handleModal}
        >
          <EditPencil />{' '}
        </button>
      </div>
      <div className="d-flex justify-content-between align-items-center pb-4 pb-md-0 pt-md-0 ">
        <div>
          {/* <span className={'clr-dark-green f-s14 d-flex'}>
            {' '}
            {orderTypeValidationMessage}{' '}
          </span> */}
          <span className="d-block text-start font-rale f-s16 f-w7 f-sm-s14 f-sm-w5 ">
            {orderTypeValidationMessage}
          </span>
          {isConvertedDate && (
            <span className="d-block text-start font-rale f-s16 f-w5 f-sm-s14">
              {isConvertedDate}
            </span>
          )}
        </div>
        <div className={isOrdered || hideEditButton ? 'd-none' : ''}>
          <button
            className={`btn  f-s16 text-decoration-underline clr-text-grey ms-3 p-0 d-none d-md-block f-w6`}
            onClick={handleModal}
          >
            Edit
          </button>
        </div>
      </div>

      <hr className="custom_hr_sty d-md-block d-none my-4" />
    </>
  );
};

export default OrderDetailsPickupMethod;
