import React from 'react';
import FavoriteLocation from 'src/components/FavoriteLocation/FavoriteLocation';
import { s3BaseUrl } from 'src/constants';

interface IActiveLocationProps {
  styleClasses: { readonly [key: string]: string };
  location: any;
  isDeviceIos: boolean;
  isGuest: boolean;
  hovered: number;
  favoriteButtonHandler: any;
  favoriteLocations: number[];
  handleSelectedLocation: (LocationModel) => void;
}

const ActiveLocation: React.FC<IActiveLocationProps> = ({
  isGuest,
  styleClasses,
  location,
  isDeviceIos,
  hovered,
  favoriteButtonHandler,
  favoriteLocations,
  handleSelectedLocation,
}) => {
  const isFavorite = () => {
    return favoriteLocations.includes(location.id);
  };
  const locationDistance = () => {
    if (
      location?.distance !== null &&
      location?.distance !== undefined &&
      location?.distance > 0
    ) {
      return (
        <React.Fragment>
          <span className="d-block f-s14 h-18 font-Visby-cf">
            {location.distance} miles
          </span>
        </React.Fragment>
      );
    }
  };
  const handleLocationSelect = (location) => {
    if (location.is_live) handleSelectedLocation(location);
  };
  return (
    <React.Fragment>
      <li
        className={`${styleClasses.location_card} ${styleClasses.selected_Location_card} d-flex align-items-center justify-content-between`}
        onClick={() => handleLocationSelect(location)}
      >
        <div className="d-flex gap-3 align-items-center">
          <div className={styleClasses.location_image}>
            <img
              src={
                isDeviceIos
                  ? `${s3BaseUrl}/Locations/` +
                    location.id +
                    '/location-' +
                    location.id +
                    '.jpeg'
                  : `${s3BaseUrl}/Locations/` +
                    location.id +
                    '/location-' +
                    location.id +
                    '.webp'
              }
              alt="image"
            />
          </div>
          <div>
            <p
              className={`f-s12 font-Visby-cf h-14 mb-1 f-w8 clr-dark-green text-uppercase ${styleClasses.location_info}`}
            >
              SELECTED LOCATION
            </p>
            <h4 className="f-s20 mb-1 h-22 font-Cls text-capilatize">
              {location.name}
            </h4>
            <span className="d-block f-s14 h-20 font-Visby-cf">
              {' '}
              {location.address}
            </span>
            <span className="d-block f-s14 h-20 font-Visby-cf">
              {location.city_state_zip}
            </span>
            <span className="d-block f-s14 h-20 font-Visby-cf clr-dark-green">
              {location.phone}
            </span>
          </div>
        </div>

        <div
          className={`${styleClasses.card_info_content} d-flex flex-column justify-content-center align-items-center`}
        >
          {!isGuest && (
            <div className="mb-2">
              <FavoriteLocation
                handleFavoriteToggle={(e) => favoriteButtonHandler(e, location)}
                favorite={isFavorite()}
              />
            </div>
          )}
          {/* <img
                        src={require("../../../../../src/images/Info.svg")}
                        alt="icon"
                        className='mb-1'
                    /> */}
          {locationDistance()}
        </div>
      </li>
      <hr className={styleClasses.border_bottom} />
    </React.Fragment>
  );
};

export default ActiveLocation;
