import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Route, RouteProps, useLocation } from 'react-router';

import { REACT_APP_DOMAIN } from '../../constants';

import { META_TAGS } from './MetaTags';
import { CampaignTriggerContext } from 'src/context/CampaignTriggerContext';
import Loader from '../Loader/Loader';

interface IReactHelmetWrapper {
  path: string;
  exact?: boolean;
  routeComponent: any;
}

const ReactHelmetWrapper: React.FunctionComponent<IReactHelmetWrapper> = ({
  routeComponent: RouteComponent,
  exact,
  path,
}) => {
  const { pathname } = useLocation();
  const campaignContext = useContext(CampaignTriggerContext);

  const { campaignTriggerLoading } = campaignContext;
  if (campaignTriggerLoading) {
    return <Loader lightBg={true} />;
  }

  const renderComponentWithHelmet = (routeProps: RouteProps) => {
    return (
      <React.Fragment>
        <Helmet>
          <link
            rel="canonical"
            href={`${REACT_APP_DOMAIN}${pathname ? pathname : ''}`}
          />
          {META_TAGS.hasOwnProperty(pathname) &&
            META_TAGS[pathname].length > 0 &&
            META_TAGS[pathname]}
        </Helmet>
        <RouteComponent {...routeProps} />
      </React.Fragment>
    );
  };

  return (
    <Route
      path={path}
      exact={exact ? exact : true}
      render={renderComponentWithHelmet}
    ></Route>
  );
};

export default ReactHelmetWrapper;
