import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styleClasses from '../../../../components/ItemOrderModal/itemOrder.module.scss';
import {
  DELIVERY_ORDER_TYPE,
  PICK_UP_METHOD_GOOD_LIFE_LANE,
  PICK_UP_METHOD_IN_STORE,
  ROUTE_CHECKOUT,
  ROUTE_LOCATIONS,
} from '../../../../constants';
import {
  setShowCart,
  updatePickUpMethod,
} from '../../../../redux/slices/cartSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store/store';
// src / components / ItemOrderModal / itemOrder.module.scss
import CurbSideVehicleDetails from './Curbside/CurbSideVehicleDetails';
import PickUpSlots from './PickupSlots';

import { brazeLogCustomEventCheckoutStarted } from 'src/helper/brazeHelper';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';

interface IOrderType {
  subTotal?: number;
  tax?: number;
  total?: number;
  closePickUpMethodModal?: (boolean) => void;
  editPickupType?: (boolean) => void;
  handleChangeOrderType?: boolean;
  isEditSlot?: boolean;
}

export const OrderType = (props: IOrderType) => {
  const {
    tax,
    subTotal,
    total,
    closePickUpMethodModal,
    editPickupType,
    handleChangeOrderType,
    isEditSlot,
  } = props;
  const locationInfo = useAppSelector((state) => state.location);
  const {
    order_types: { Pickup },
  } = locationInfo.selectedStore;
  const doStoreHasGoodLifeLane = Pickup?.map(
    (pickUpTypes) => pickUpTypes.value,
  ).includes(PICK_UP_METHOD_GOOD_LIFE_LANE);

  const [showVehicleDetails, setShowVehicleDetails] = useState(false);
  const cart = useAppSelector((state) => state.cart);
  const isItMobile = useCheckMobileScreen();
  const pickUpMethodDispatch = useAppDispatch();
  const history = useHistory();
  const isShownVehicleDetails = (isShown: boolean) => {
    setShowVehicleDetails(isShown);
  };
  const closeShowVehicleModal = () => {
    closePickUpMethodModal(true);
    pickUpMethodDispatch(setShowCart(false));
    const slotDetails = cart?.orderDetails?.PickupSlotDetails;
    brazeLogCustomEventCheckoutStarted(
      cart,
      { tax, subTotal, total },
      isItMobile,
      slotDetails,
      locationInfo.selectedStore,
    );
    history.push(ROUTE_CHECKOUT);
  };
  let selectedPickupMethod = useAppSelector((state) =>
    state.cart.orderDetails?.PickupSlotDetails?.pickupMethod !=
    DELIVERY_ORDER_TYPE
      ? state.cart.orderDetails?.PickupSlotDetails?.pickupMethod
      : PICK_UP_METHOD_IN_STORE,
  );

  useEffect(() => {
    handlePickupMethod(selectedPickupMethod);
  }, [selectedPickupMethod]);

  const handlePickupMethod = (pickUpMethod: string) => {
    pickUpMethodDispatch(updatePickUpMethod(pickUpMethod));
  };
  const handleChangeToDeliveryModal = () => {
    editPickupType(true);
    closePickUpMethodModal(true);
  };

  const handleLocationSelect = () => {
    closePickUpMethodModal(true);
    pickUpMethodDispatch(setShowCart(false));
    history.push(ROUTE_LOCATIONS);
  };

  return (
    <div className={` order-pickup-wrapper  ${styleClasses.itemOrderWrapper}`}>
      {!showVehicleDetails && (
        <>
          <h5 className="f-s22 font-Cls text-center fw-normal pb-3 text-capitalize mt-5 mt-md-0">
            Select Date and Time
          </h5>
          <PickUpSlots
            tax={tax}
            subTotal={subTotal}
            total={total}
            isEditSlot={isEditSlot}
            closePickUpMethodModal={closePickUpMethodModal}
            isShownVehicleDetails={isShownVehicleDetails}
            pickupMethod={PICK_UP_METHOD_IN_STORE}
          />
          <div className="d-flex justify-content-center mb-2">
            <button
              type="button"
              className={`btn mt-2  p-0 f-w6 p-16 clr-dark-green font-Vcf`}
              onClick={() => handleLocationSelect()}
            >
              Change Location
            </button>
          </div>
          {!handleChangeOrderType && (
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className={`btn  p-0 mt-1 f-w6 p-16 clr-dark-green font-Vcf`}
                onClick={handleChangeToDeliveryModal}
              >
                Change To Delivery
              </button>
            </div>
          )}
        </>
      )}

      {showVehicleDetails && (
        <CurbSideVehicleDetails
          closeShowVehicleModal={closeShowVehicleModal}
          isShownVehicleDetails={isShownVehicleDetails}
        />
      )}
    </div>
  );
};
