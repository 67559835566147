import React from 'react';

import {
  PRIVACY_POLICY_ROUTE,
  REWARDS_DETAILS_ROUTE,
  ROUTE_CAREERS,
  ROUTE_CATERING,
  ROUTE_CATERING_REQUEST_FORM,
  ROUTE_FUNDRAISING,
  ROUTE_HOME,
  ROUTE_LOCATIONS,
  ROUTE_MENU,
  ROUTE_MENU_CATERING,
  ROUTE_ORDER_LANDING,
  ROUTE_ORDER_ONLINE,
  ROUTE_OUR_KITCHEN,
  ROUTE_TALK_TO_US,
  TERMS_ROUTE,
  TERMS_ROUTE_HTML,
} from '../../constants';
/**
 * TODO: Add meta tags for each page for SEO optimization
 * META_TAGS["KEY NAME"] KEY NAME should match the exact pathname
 */
export const META_TAGS: { [key: string]: JSX.Element[] } = {
  '/menu/Packages': [
    <title>Packages | Café Zupas </title>,
    <meta
      name="keywords"
      content="Includes 12 cups of our most popular soups & your choice of salad tray"
    ></meta>,
  ],
  '/menu/Sandwich Trays': [
    <title>Sandwhich Trays | Café Zupas</title>,
    <meta
      name="keywords"
      content="Includes a variety of 16 half sandwich, your choice of salad tray & 8 bags of chips"
    ></meta>,
  ],
  '/menu/Salad Trays': [
    <title>Salad Trays | Café Zupas</title>,
    <meta
      name="keywords"
      content="Our salad trays come with a selection of our most popular salads."
    ></meta>,
  ],
  '/menu/Soup Trays': [
    <title>Soup Trays | Café Zupas</title>,
    <meta
      name="keywords"
      content="Our soup trays come with a variety of our most popular soups. Select 6 or 12 cups. Option to customize with your preferred soups!"
    ></meta>,
  ],
  '/menu/Box Lunches': [
    <title>Box Lunches | Café Zupas</title>,
    <meta name="keywords" content="Your Choice Of Bowls"></meta>,
  ],
  '/menu/Desserts': [
    <title>Desserts | Café Zupas</title>,
    <meta name="keywords" content="Desserts"></meta>,
  ],
  '/menu/Drinks': [
    <title>Drinks | Café Zupas</title>,
    <meta name="keywords" content="Drinks"></meta>,
  ],
  '/menu/Sides': [
    <title>Sides | Café Zupas</title>,
    <meta name="keywords" content="Sides"></meta>,
  ],
};

META_TAGS[ROUTE_OUR_KITCHEN] = [
  <title>Our Kitchen: House-Made, Quality Ingredients — Café Zupas</title>,
  <meta name="keywords" content="cafe ingredients"></meta>,
];
META_TAGS[ROUTE_MENU] = [
  <title>Menu — Salads, Soups, Sandwiches, Protein Bowls — Café Zupas</title>,
  <meta name="keywords" content="cafe zupas menu"></meta>,
];
META_TAGS[ROUTE_LOCATIONS] = [
  <title>Locations Near Me — Café Zupas</title>,
  <meta name="keywords" content="zupas near me"></meta>,
];
META_TAGS[ROUTE_ORDER_ONLINE] = [
  <title>Order Online — Café Zupas</title>,
  <meta name="keywords" content="zupas order online"></meta>,
];
META_TAGS[ROUTE_ORDER_LANDING] = [
  <title>Start An Online Order — Café Zupas</title>,
  <meta name="keywords" content="zupas order online"></meta>,
];
META_TAGS[ROUTE_CAREERS] = [
  <title>Careers — We're Hiring! — Café Zupas</title>,
  <meta name="keywords" content="zupas hiring"></meta>,
];
META_TAGS[ROUTE_MENU_CATERING] = [
  <title>Catering Menu — Boxed Lunches & Party Salads — Café Zupas</title>,
  <meta name="keywords" content="zupas catering menu"></meta>,
];
META_TAGS[ROUTE_CATERING] = [
  <title>Catering — Variety Sandwiches, Soups and Salads — Café Zupas</title>,
  <meta name="keywords" content="zupas catering"></meta>,
];
META_TAGS[ROUTE_HOME] = [
  <title>Soup, Salad and Sandwich Kitchen & Catering — Café Zupas</title>,
  <meta name="keywords" content="soup and salad"></meta>,
];
META_TAGS[ROUTE_FUNDRAISING] = [
  <title>Fundraiser Events — Support Your Local School — Café Zupas</title>,
  <meta name="keywords" content="fundraiser events"></meta>,
];
META_TAGS[REWARDS_DETAILS_ROUTE] = [
  <title>Catering Rewards — Café Zupas</title>,
  <meta name="keywords" content="catering rewards"></meta>,
];
META_TAGS[ROUTE_TALK_TO_US] = [<title>Contact Us — Café Zupas</title>];
META_TAGS[ROUTE_CATERING_REQUEST_FORM] = [
  <title>Request Catering — Café Zupas</title>,
];

META_TAGS[TERMS_ROUTE_HTML] = [<title>Terms & Conditions — Café Zupas</title>];
META_TAGS[TERMS_ROUTE] = [<title>Terms & Conditions — Café Zupas</title>];
META_TAGS[PRIVACY_POLICY_ROUTE] = [<title>Privacy Policy — Café Zupas</title>];
