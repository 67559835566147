import PickUpSlots from './ItemPickup/PickupMethod/PickupSlots';

interface IItemDeliveryDateAndTimeProps {
  subTotal?: number;
  tax?: number;
  total?: number;
  closeDeliveryMethodModal?: (boolean) => void;
  editDeliveryType?: (boolean) => void;
  handleChangeOrderType?: boolean;
  isEditSlot?: boolean;
}
const ItemDeliveryDateAndTime = (props: IItemDeliveryDateAndTimeProps) => {
  const {
    tax,
    subTotal,
    total,
    closeDeliveryMethodModal,
    editDeliveryType,
    handleChangeOrderType,
    isEditSlot,
  } = props;

  const handleChangeToPickupModal = () => {
    closeDeliveryMethodModal(true);
    editDeliveryType(true);
  };

  return (
    <div>
      <PickUpSlots
        subTotal={subTotal}
        tax={tax}
        total={total}
        isEditSlot={isEditSlot}
        closeDeliveryMethodModal={closeDeliveryMethodModal}
      />
      {!handleChangeOrderType && (
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className={`btn btn-custom p-0 f-w6 p-16 clr-dark-green font-Vcf mb-5`}
            onClick={() => handleChangeToPickupModal()}
          >
            Change To Pick-Up
          </button>
        </div>
      )}
    </div>
  );
};

export default ItemDeliveryDateAndTime;
