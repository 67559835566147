import { useEffect, useState } from 'react';
import styleClasses from '../order.module.scss';
// import ItemOrderModal from "../../../components/ItemGrid/ItemOrder";
import ItemOrderModal from 'src/components/ItemOrderModal/ItemOrderModal';
import CustomModal from 'src/components/customModal/CustomModal';
import { awsAssetsFetcher } from 'src/utils/awsAssetsFetcher';
import { useAppSelector } from '../../../redux/store/store';
import { EditPencilIcon } from 'src/assets/images/Svgs/svg';

interface IDeliveryLocationProps {
  closeOpenOrderModal?: () => void;
  editChangeToDelivery?: boolean;
  handleShowDeliveryMethodModal?: (boolean) => void;
  orderDeliveryValidationMessage?: string;
  setOrderDeliveryInvalidMessage: (string) => void;
  storeDetilsForAddPayment?: any;
}
const DeliveryLocation = (props: IDeliveryLocationProps) => {
  const {
    handleShowDeliveryMethodModal,
    closeOpenOrderModal,
    setOrderDeliveryInvalidMessage,
    editChangeToDelivery,
    orderDeliveryValidationMessage,
    storeDetilsForAddPayment,
  } = props;
  const deliveryAddress =
    storeDetilsForAddPayment ??
    useAppSelector((state) => state.cart?.orderDetails?.delivery_address || '');

  const [showEditAddress, setShowEditAddress] = useState<boolean>(false);
  const closeEditAddressModal = () => {
    setShowEditAddress(false);
    // closeOpenOrderModal()
  };

  useEffect(() => {
    if (deliveryAddress) setOrderDeliveryInvalidMessage('');
  }, [deliveryAddress]);

  return (
    <div>
      <div className="pb-md-0 pb-4 ">
        <div className="d-flex justify-content-between align-items-center">
          <h2
            className={`f-s20 text-start h-22 text-uppercase font-Cls fw-normal mb-md-3 f-sm-s16  `}
          >
            Delivery Location
          </h2>
          {!storeDetilsForAddPayment && (
            <button
              type="button"
              className={` btn  f-s16 text_underline clr-text-grey ms-3 p-0 d-md-none`}
              onClick={() => setShowEditAddress(true)}
            >
              <span className="">
                <EditPencilIcon />
              </span>
            </button>
          )}
        </div>

        <span className={'clr-dark-green f-s14 d-flex'}>
          {orderDeliveryValidationMessage}
        </span>
        <div
          className={`${styleClasses.location_wrapper}  d-flex align-items-start`}
        >
          <div
            className={`${styleClasses.ser_user_img_wrap} me-3 d-none d-md-block`}
          >
            <img
              className={styleClasses.location_image}
              src={awsAssetsFetcher(
                'Catering/order/delivery_type_vehicle',
                'svg',
              )}
              alt="image"
            />
          </div>
          <div className={`${styleClasses.location_info_box} flex-fill `}>
            <div
              className={`${styleClasses.location_name} d-flex justify-content-between align-items-start sub_title1 w-100`}
            >
              <h3 className="f-s22 text-start h-22 text-uppercase font-Visby-cf f-w6 mb-md-1 f-sm-s14 f-sm-w5 mb-0">
                {deliveryAddress.address_name}
              </h3>
              {!storeDetilsForAddPayment && (
                <button
                  type="button"
                  className={` btn text-decoration-underline f-s16 d-md-block d-none text_underline clr-text-grey ms-3 p-0`}
                  onClick={() => setShowEditAddress(true)}
                >
                  <span> Edit </span>
                </button>
              )}
            </div>
            <p
              className={`${styleClasses.location_address} mb-0 text-style2 text-start font-Visby-cf f-sm-w5  f-sm-s14`}
            >
              {deliveryAddress.street_name},{' '}
              {deliveryAddress?.apartment_no
                ? `${deliveryAddress?.apartment_no},`
                : ''}
              {deliveryAddress.city}, {deliveryAddress.zipcode}{' '}
              {deliveryAddress.state}
            </p>
          </div>
        </div>
        <hr className="custom_hr_sty d-none d-md-block my-4" />
      </div>
      <CustomModal
        showModal={showEditAddress}
        title={''}
        closeModal={closeEditAddressModal}
        modalDialogClasses={'itemOrderModal'}
      >
        <ItemOrderModal
          handleShowDeliveryMethodModal={handleShowDeliveryMethodModal}
          closeOpenOrderModal={closeEditAddressModal}
          editChangeToDelivery={editChangeToDelivery}
          editAddressOnCheckout={deliveryAddress}
        />
      </CustomModal>
    </div>
  );
};

export default DeliveryLocation;
